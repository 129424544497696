<template>
  <span class="inline-action-buttons">
    <img class="img" v-if="img" :src="img"/>
    <a :title="$t('edit')"
       @click="editPlanTreatment"
       v-b-tooltip.hover
       class="data-table-action">
    <EditSvg/><span class="action-button-mob-title">{{ $t('edit') }}</span>
  </a>
    <a :title="$t('duplicate')"
       @click="copyPlanTreatment"
       v-b-tooltip.hover
       class="data-table-action">
    <CopySvg/><span class="action-button-mob-title">{{ $t('duplicate') }}</span>
  </a>
    <a :title="$t('refresh_prices')"
       @click="updatePlanTreatmentPrices"
       v-b-tooltip.hover
       class="data-table-action">
    <RefreshSvg/><span class="action-button-mob-title">{{ $t('refresh_prices') }}</span>
  </a>
    <a :title="$t('sign')"
       v-if="!object.signed_at"
       @click="sign"
       v-b-tooltip.hover
       class="data-table-action">
    <SignSvg class="svg-light svg-size-xl"/><span class="action-button-mob-title">{{ $t('sign') }}</span>
  </a>
    <a class="data-table-action"
       v-b-tooltip.hover
       :title="$t('print')">
      <b-dropdown right variant="link" no-caret>
        <template #button-content>
          <PrintSvg/><span class="action-button-mob-title">{{ $t('print') }}</span>
        </template>
        <a class="dropdown-item pointer" @click="printPlan">
          {{ $t('standard') }}
        </a>
        <a class="dropdown-item pointer" @click="printPlanGrouped">
          {{ $t('grouped') }}
        </a>
        <a class="dropdown-item pointer" @click="printPlanGroupedByTeeth">
          {{ $t('grouped_by_teeth') }}
        </a>
        <a class="dropdown-item pointer" @click="printPlanGroupedByServices">
          {{ $t('grouped_by_services') }}
        </a>
        <a class="dropdown-item pointer" @click="printPlanFact">
          {{ $t('plan_fact') }}
        </a>
        <a class="dropdown-item pointer" @click="printWithFormula">
          {{ $t('with_formula') }}
        </a>
         <a class="dropdown-item pointer" @click="printRouteList">
          {{ $t('route_list') }}
        </a>
        <div class="dropdown-divider"></div>
        <div class="dropdown-header font-weight-bold">{{  $t('by_complexes') }}</div>
        <a class="dropdown-item pointer"
           v-for="(complex, index) in object.visits"
           :key="`visit-${complex.id}`"
           @click="printPlanComplex(complex)">
          {{ complex.title ? complex.title : $t('complex')+' '+(index+1) }}
        </a>
      </b-dropdown>
    </a>
    <PlanSignModal v-if="showModal"
                   :plan="object"
                   @hidden="showModal = false"/>
    <OptionsPopup v-if="optionsModalOpen"
                  :title="$t('choose_signer')"
                  @changed="modalResolve"
                  :options="signerOptions"/>
  </span>
</template>

<script>
import EditSvg from '@/assets/svg-vue/general/edit.svg'
import PrintSvg from '@/assets/svg-vue/general/print2.svg'
import CopySvg from '@/assets/svg-vue/patients/copy2.svg'
import SignSvg from '@/assets/svg-vue/patients/sign.svg'
import RefreshSvg from '@/assets/svg-vue/general/refresh2.svg'
import JwtService from "@/services/jwt.service"
import { mapState } from "vuex"
import { PatientService, CalendarService } from "@/services/api.service"
import PlanSignModal from "@/components/parts/planning/PlanSignModal.vue"
import OptionsPopup from "@/components/parts/general/OptionsPopup.vue"

export default {
  name: "PatientDatatablePlanTreatmentActions",
  components: {
    OptionsPopup,
    PlanSignModal,
    EditSvg,
    PrintSvg,
    CopySvg,
    SignSvg,
    RefreshSvg
  },
  props: {
    object: {
      type: Object
    }
  },
  computed: {
    ...mapState({
      clinic: state => state.auth.clinic,
      branch: state => state.auth.branch,
      patient: state => state.patient.patient,
    }),
    hasRepresentative() {
      return this.patient.representative_id || this.patient.representative_fio
    },
    signerOptions() {
      return [
        { id: 'patient', title: this.$t('patient') },
        { id: 'representative', title: this.$t('representative_noun') },
      ]
    }
  },
  data() {
    return {
      img: null,
      showModal: false,
      optionsModalOpen: false,
      modalResolve: () => {}
    }
  },
  methods: {
    editPlanTreatment() {
      this.$store.commit('setVisit', this.copyObject(this.object))
      this.$bvModal.show('plan-treatment-modal')
    },
    copyPlanTreatment() {
      CalendarService.duplicatePlanTreatment(this.object.planning_id).then(() => {
        this.$noty.info(this.$t('success_message'))
        this.$store.commit('incrementDataTableCounter')
      }).catch((e) => { console.log(e) })

    },
    updatePlanTreatmentPrices() {
      CalendarService.updatePlanTreatmentPrices(this.object.planning_id).then(() => {
        this.$noty.info(this.$t('success_message'))
        // this.$store.commit('incrementDataTableCounter')
      }).catch((e) => { console.log(e) })
    },
    async printPlan() {
      let patientSigner = ''
      if(this.hasRepresentative) {
        patientSigner = await this.openOptionsModal()
        if(!patientSigner) return
      }
      let link = JwtService.getPrintPrelink(this.clinic)
      window.open(`${link}/plan/${this.object.planning_id}?patientSigner=${patientSigner}&branchId=${this.branch}`, '_blank')
    },
    async printPlanComplex(complex) {
      let patientSigner = ''
      if(this.hasRepresentative) {
        patientSigner = await this.openOptionsModal()
        if(!patientSigner) return
      }
      let link = JwtService.getPrintPrelink(this.clinic)
      window.open(`${link}/plan/${this.object.planning_id}?complexId=${complex.id}&patientSigner=${patientSigner}&branchId=${this.branch}`, '_blank')
    },
    async printPlanGrouped() {
      let patientSigner = ''
      if(this.hasRepresentative) {
        patientSigner = await this.openOptionsModal()
        if(!patientSigner) return
      }
      let link = JwtService.getPrintPrelink(this.clinic)
      window.open(`${link}/plan/${this.object.planning_id}?grouped=1&patientSigner=${patientSigner}&branchId=${this.branch}`, '_blank')
    },
    async printPlanGroupedByTeeth() {
      let patientSigner = ''
      if(this.hasRepresentative) {
        patientSigner = await this.openOptionsModal()
        if(!patientSigner) return
      }
      let link = JwtService.getPrintPrelink(this.clinic)
      window.open(`${link}/plan/${this.object.planning_id}?grouped=1&by_teeth=1&patientSigner=${patientSigner}&branchId=${this.branch}`, '_blank')
    },
    async printPlanGroupedByServices() {
      let patientSigner = ''
      if(this.hasRepresentative) {
        patientSigner = await this.openOptionsModal()
        if(!patientSigner) return
      }
      const link = JwtService.getPrintPrelink(this.clinic)
      window.open(`${link}/plan/${this.object.planning_id}?grouped=1&by_services=1&patientSigner=${patientSigner}&branchId=${this.branch}`, '_blank')
    },
    async printPlanFact() {
      let patientSigner = ''
      if(this.hasRepresentative) {
        patientSigner = await this.openOptionsModal()
        if(!patientSigner) return
      }
      let link = JwtService.getPrintPrelink(this.clinic)
      window.open(`${link}/plan/${this.object.planning_id}?planfact=1&patientSigner=${patientSigner}&branchId=${this.branch}`, '_blank')
    },
    async openOptionsModal() {
      this.optionsModalOpen = true
      await this.$nextTick()
      this.$bvModal.show('options-popup-modal')
      return new Promise((resolve) => {
        this.modalResolve = resolve
      });
    },
    async sign() {
      this.showModal = true
      await this.$nextTick()
      this.$bvModal.show('plan-sign-modal')
    },
    async printWithFormula() {
      try {
        const el = document.getElementById('patientFormulaSelectedView')
        const options = {
          type: 'dataURL',
          allowTaint: true,
          onclone: function(doc) {
            const hiddenDiv = doc.getElementById('patientFormula')
            hiddenDiv.style.display = 'block'
          }
        }
        const style = document.createElement('style') // this style addition is a hack to keep the styling, otherwise some paddings appear and image is cropped
        document.head.appendChild(style)
        style.sheet?.insertRule('body > div:last-child img { display: inline-block; }')
        await this.$nextTick()
        try {
          const output = await this.$html2canvas(el, options)
          style.remove()
          const res = await PatientService.sendFormulaImage(this.object.client_id, output)
          if(res.data) {
            let patientSigner = ''
            if(this.hasRepresentative) {
              patientSigner = await this.openOptionsModal()
              if(!patientSigner) return
            }
            const link = JwtService.getPrintPrelink(this.clinic)
            window.open(`${link}/plan/${this.object.planning_id}?formula=1&by_services=1&patientSigner=${patientSigner}&branchId=${this.branch}`, '_blank')
          }
        } catch (e) {
          console.log(e)
        }
      } catch (e) {
        console.log(e)
      }
    },
    async printRouteList() {
      let patientSigner = ''
      // if(this.hasRepresentative) {
      //   patientSigner = await this.openOptionsModal()
      //   if(!patientSigner) return
      // }
      let link = JwtService.getPrintPrelink(this.clinic)
      window.open(`${link}/plan/${this.object.planning_id}?route_list=1&patientSigner=${patientSigner}&branchId=${this.branch}`, '_blank')
    }
  }
}
</script>

<style scoped lang="scss">
.inline-action-buttons {
  display: contents;
}
</style>
