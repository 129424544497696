<template>
  <div v-if="options">
    <DataTable :options="options"
               :params="{ clinic_id: clinic.id }"
               module="superadmin"/>
    <TechSupportLogToClinicModal/>
  </div>
</template>

<script>

import DataTable from "@/components/parts/general/DataTable"
import { superadmin_technic_options } from "@/data/superadmin/technic"
import { mapState } from "vuex"
import TechSupportLogToClinicModal from "@/components/parts/superadmin/TechSupportLogToClinicModal.vue"

export default {
  name: "SuperAdminTechnics",
  components: {
    TechSupportLogToClinicModal,
    DataTable
  },
  head() {
    return {
      title: this.$t('technics'),
    }
  },
  computed: {
    ...mapState({
      clinic: state => state.auth.clinic
    })
  },
  data() {
    return {
      options: null
    }
  },
  mounted() {
    this.options = superadmin_technic_options()
    this.$store.commit('setEloquentOptions', this.options)
  }
}
</script>
