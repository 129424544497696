export const complexServiceAdapter =
    ({
       title,
       services,
       discount_percent
     }) => ({
      title: title ?? null,
      discount_percent: discount_percent ?? 0,
      services: services.map(s => ({
        service_id: s.id,
        amount: s.pivot.amount
      }))
    })
