export default {
    tag_ACCRUED_BONUSES: "Bonuslar yığılmışdır",
    tag_ADDRESS: "Pasiyentin ünvanı",
    tag_ADDRESS_PREDSTAVITEL: "Müştərinin nümayəndəsinin ünvanı",
    tag_BIRTHDAY: "Pasiyentin doğum tarixi",
    tag_BIRTHDAY_PREDSTAVITEL: "Pasiyent nümayəndəsinin doğum tarixi",
    tag_BRANCH_ADDRESS: "Filialın ünvanı",
    tag_BRANCH_BIN: "Filialın biznes ID nömrəsi",
    tag_BRANCH_INN: "Filialın VÖEN-i",
    tag_BRANCH_KPP: "Filialın unikal nömrəsi",
    tag_BRANCH_LICENSE: "Filialın lisenziyası",
    tag_BRANCH_OGRN: "Filialın qeydiyyat nömrəsi",
    tag_BRANCH_TITLE: "Filialın adı",
    tag_CARD_CLIENT: "Müştərinin kart nömrəsi",
    tag_CLIENT: "Müştərinin A.S.A.",
    tag_CLIENT_BONUSES: "Müştərinin bonusları",
    tag_CLIENT_NAME: "Müştərinin adı",
    tag_CLIENT_NAME_MNAME: "Müştərinin adı və atasının adı",
    tag_CLINIC: "Klinikanın adı",
    tag_COMPLEX_STATUS: "Müalicə planının kompleks statusu",
    tag_COST_DISCOUNTED: "Endirim nəzərə alınmaqla xidmətlərin qiyməti",
    tag_COST_ORIGINAL: "Endirimsiz xidmətlərin qiyməti",
    tag_DATA: "Sənədin yaradılma tarixi",
    tag_DATA_VIZIT: "Vizit tarixi",
    tag_DATE: "Göndərişin yaradılma tarixi",
    tag_DATE_FIRST_VIZIT: "İlk vizitin tarixi",
    tag_DIAGNOZ: "Diaqnoz",
    tag_DIAGNOZ_PLAN: "Müalicə planındakı diaqnoz",
    tag_DOCTOR: "Həkimin A.S.A.",
    tag_DOCUMENT_TYPE_PREDSTAVITEL: "Pasiyent nümayəndəsinin sənəd növü",
    tag_FIO_CLIENT: "Müştərinin A.S.A.",
    tag_FIO_DIRECTOR: "Direktorun A.S.A.",
    tag_IIN: "Pasiyentin FİN-i",
    tag_INN: "Pasiyentin VÖEN-i",
    tag_LEGAL_ADDRESS: "Klinikanın hüquqi ünvanı",
    tag_LEGAL_COMPANY_NAME: "Klinikanın hüquqi adı",
    tag_PASSPORT: "Pasiyentin pasport nömrəsi",
    tag_PASSPORT_CODE: "Pasiyentin pasport bölməsi kodu",
    tag_PASSPORT_CODE_PREDSTAVITEL: "Pasiyent nümayəndəsinin pasport bölməsi kodu",
    tag_PASSPORT_DATE: "Pasiyentin pasportunun verilmə tarixi",
    tag_PASSPORT_DATE_PREDSTAVITEL: "Pasiyent nümayəndəsinin sənədinin verilmə tarixi",
    tag_PASSPORT_ISSUED: "Pasiyentin pasportunu verən qurum",
    tag_PASSPORT_ISSUED_PREDSTAVITEL: "Pasiyent nümayəndəsinin sənədini verən qurum",
    tag_PASSPORT_PREDSTAVITEL: "Müştərinin nümayəndəsinin pasport nömrəsi",
    tag_PASSPORT_SERIES: "Pasiyentin pasport seriyası",
    tag_PASSPORT_SERIES_PREDSTAVITEL: "Müştərinin nümayəndəsinin sənəd seriyası",
    tag_PHONE: "Müştərinin telefonu",
    tag_PHONE_CLIENT: "Müştərinin telefonu",
    tag_PHONE_PREDSTAVITEL: "Müştərinin nümayəndəsinin telefonu",
    tag_PLAN_STATUS: "Müalicə planının statusu",
    tag_PLAN_TITLE: "Müalicə planının adı",
    tag_PREDSTAVITEL_PACIENTA: "Müştərinin nümayəndəsinin A.S.A.",
    tag_SNILS: "Pasiyentin fərdi şəxsi hesabın sığorta nömrəsi",
    tag_SNILS_PREDSTAVITEL: "Müştərinin nümayəndəsinin fərdi şəxsi hesabın sığorta nömrəsi",
    tag_TASK: "Tapşırıq mətni",
    tag_URL_VIZIT_CONFIRMATION: "Viziti təsdiqləmə linki",
    tag_URL_VIZIT_REVIEW: "Vizit haqqında rəy linki",
    tag_USLUGI: "Xidmətlər",
    tag_USLUGI_PLAN: "Müalicə planındakı xidmətlər",
    tag_VISIT_DOCTOR: "Vizitin həkiminin A.S.A.",
}
