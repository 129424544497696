<template>
  <div :class="{ collapsed: collapsed }">
    <SuperAdminSidebar/>
    <div class="sidebar-offset" :class="{ collapsed: collapsed }">
      <SuperAdminHeader/>
      <main>
        <div class="dentist-container">
          <slot/>
        </div>
      </main>
    </div>
    <div class="mobile-shadow" @click="$store.commit('toggleCollapsed')"></div>
    <FormModal v-if="showModal"/>
<!--    <SuperAdminLaravelEcho/>-->
  </div>
</template>
<script>

import { mapState } from "vuex"
import FormModal from "@/components/parts/datatable/FormModal"
import SuperAdminHeader from "@/components/layouts/SuperAdminHeader"
import SuperAdminSidebar from "@/components/layouts/SuperAdminSidebar"
// import SuperAdminLaravelEcho from "@/components/parts/superadmin/SuperAdminLaravelEcho"

export default {
  name: "SuperAdmin",
  components: {
    // SuperAdminLaravelEcho,
    SuperAdminSidebar,
    SuperAdminHeader,
    FormModal,
  },
  computed: {
    ...mapState({
      collapsed: state => state.dom.sidebarCollapsed,
      showModal: state => state.datatable.showModal,
    })
  },
}
</script>
