<template>
  <svg id="Layer_1" style="enable-background:new 0 0 24 24;" version="1.1" viewBox="0 0 24 24" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g>
		<g>
			<line class="st1" x1="2" x2="16.4" y1="12" y2="12" style="fill:none;stroke-width:3;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;"/>
		</g>
      <g>
		<path class="st1" d="M12.1,0.8h7.4c1.4,0,2.5,1.1,2.5,2.5v17.5c0,1.4-1.1,2.5-2.5,2.5h-7.4" style="fill:none;stroke-width:3;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;"/>
		</g>
      <polyline class="st1" points="12.6,15.8 16.4,12 12.6,8.2  " style="fill:none;stroke-width:3;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;"/>
	</g>
</svg>
</template>
<script setup>
</script>
