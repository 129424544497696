<template>
  <div class="mb-4"
       v-if="wazzupEnabled">
    <b-button v-spaceduck-collapse="`wazzup-c`"
              class="btn-collapse">
      Wazzup
    </b-button>
    <spaceduck-collapse id="wazzup-c" @show="opened">
      <div class="mt-3 pb-2">
        <InputGroup v-model="wazzupKey"
                    :label="'Wazzup API Key'"/>
        <ServiceMultiSelect :label="$t('staff')"
                            class="mt-3"
                            :dblclick="false"
                            model="staff"
                            :multiple="true"
                            title="full_name"
                            :dbsearch="true"
                            v-model="wazzupUsersArr"/>
        <div v-if="wazzupKey"
             class="mb-3 mt-3">
          <div class="bg-gray p-3 mb-3"
               v-if="channels.length">
            <div class="mb-2 font-weight-bold">{{ $t('channels') }}</div>
            <div v-for="branch in branches"
                 :key="`branch-${branch.id}`">
              <div class="row">
                <div class="col-md-12 mb-3">
                  <SelectGroup :options="channels"
                               :showlabel="true"
                               valuekey="channelId"
                               :label="branch.title"
                               variant="white"
                               title="name"
                               v-model="wazzup_branch_channels[branch.id]"
                               :multiple="false"/>
                </div>
              </div>
            </div>
          </div>

          <div v-if="wazzupStatus && ppoSettings.wazzup == wazzupKey">
            <div class="alert alert-info">{{ $t('connected_successfully') }}</div>
          </div>
          <div v-if="!wazzupStatus && ppoSettings.wazzup == wazzupKey">
            <div class="alert alert-danger">{{ $t('not_connected') }}</div>
          </div>
          <button class="btn btn-themed btn-themed-squared"
                  @click="getwazzupStatus"
                  :disabled="loading">
            {{ ppoSettings.wazzup != wazzupKey ? $t('connect') : $t('refresh') }}
          </button>
          <button v-if="wazzupStatus && ppoSettings.wazzup == wazzupKey"
                  @click="launchIframe"
                  class="ml-2 btn btn-themed btn-themed-squared">
            {{ $t('open_whatsapp') }}
          </button>
        </div>
      </div>
    </spaceduck-collapse>
  </div>
</template>

<script>

import InputGroup from '@/components/form/InputGroup'
import {mapState} from "vuex"
import ServiceMultiSelect from "@/components/form/ServiceMultiSelect"
import {ClinicService, WhatsappService} from "@/services/api.service"
import SpaceduckCollapse from "@/components/parts/general/SpaceduckCollapse.vue"
import SelectGroup from "@/components/form/SelectGroup.vue"

export default {
  name: "WazzupAPIIntegration",
  components: {
    SelectGroup,
    SpaceduckCollapse,
    InputGroup,
    ServiceMultiSelect
  },
  computed: {
    ...mapState({
      clinic: state => state.auth.clinic,
      loading: state => state.dom.loading,
      wazzupStatus: state => state.crm.wazzupEnabled,
      superadmin:  state => state.auth.superadmin,
    }),
    ppoSettings() {
      return this.clinic.ppo_settings ? JSON.parse(this.clinic.ppo_settings) : {
        wazzup: '',
        wazzupUsers: [],
        wazzup_branch_channels: {}
      }
    },
    wazzupUsers() {
      return this.ppoSettings.wazzupUsers ?? []
    },
    branches() {
      return this.clinic?.branches ?? []
    }
  },
  data() {
    return {
      wazzupUsersArr: [],
      wazzupKey: '',
      wazzupEnabled: true,
      wazzup_branch_channels: {},
      channels: []
    }
  },
  methods: {
    async opened() {
      await this.getInitialUsers()
      if(this.wazzupKey) {
        await this.getwazzupStatus()
        await this.getChannels()
      }
    },
    async getwazzupStatus() {
      await ClinicService.savePPOAttribute([
            'wazzup',
            'wazzupUsers',
            'wazzup_branch_channels'
          ], [
            this.wazzupKey,
            this.wazzupUsersArr.map(x => x.id),
            this.wazzup_branch_channels
          ],
          this.superadmin,
          this.clinic.id)
      const res = await WhatsappService.getWazzupStatus(this.superadmin)
      await this.getChannels()
      this.$store.commit('setWazzupEnabled', res.data)
      this.$store.dispatch('init')
    },
    async getChannels() {
      const res = await WhatsappService.getWazzupChannels(this.superadmin)
      this.channels = res.data
      this.channels.unshift({
        channelId: 'blocked',
        name: this.$t('close_the_integration_for_this_branch')
      })
    },
    async launchIframe() {
      let res = await WhatsappService.createIframe();
      if(res.data.url) {
        this.$store.commit('setWazzupIframeUrl', res.data.url)
        await this.$nextTick()
        this.$bvModal.show('wazzup-iframe-modal')
        return
      }
      if(res.data.error) {
        this.$noty.error(res.data.error)
      }
    },
    async getInitialUsers() {
      if(this.wazzupUsers.length) {
        let res = await ClinicService.getUsersList(this.wazzupUsers, this.superadmin)
        this.wazzupUsersArr = res.data
      }
    }
  },
  async mounted() {
    this.wazzupKey = this.ppoSettings.wazzup
    this.wazzup_branch_channels = Array.isArray(this.ppoSettings.wazzup_branch_channels) ? {} : (this.ppoSettings.wazzup_branch_channels || {})
  }
}
</script>
