import Vue from "vue"

import moment from 'moment'
import i18n from "@/lang/i18n"
const resolvePath = require('object-resolve-path')

Vue.filter('nestedAttribute', function (value, attributes) {
    if(!value) return ''
    const res = resolvePath(value, attributes)
    return res === undefined ? '' : res
})

export function nestedAttribute(value, attributes) {
    if(!value) return ''
    const res = resolvePath(value, attributes)
    return res === undefined ? '' : res
}

var numeral = require("numeral");
numeral.localeData().delimiters.thousands = ' '
numeral.localeData().delimiters.decimal = '.'

Vue.filter("formatDate", function (value, format = 'DD.MM.YYYY') {
    if(value == '-') return '-'
    let v = value ? moment(value).format(format) : '';
    return v === 'Invalid date' ? '-' : v;
});

export function formatDate(value, format = 'DD.MM.YYYY') {
    if(value == '-') return '-'
    let v = value ? moment(value).format(format) : '';
    return v === 'Invalid date' ? '-' : v;
}

Vue.filter("formatDateTime", function (value, format = 'DD.MM.YYYY HH:mm') {
    if(value == '-') return value
    return value ? moment(value).format(format) : ''
});

export function formatDateTime(value, format = 'DD.MM.YYYY HH:mm') {
    if(value == '-') return value
    return value ? moment(value).format(format) : ''
}

Vue.filter("formatNumber", function (value, decimals = false) {
    return formatNumber(value, decimals)
});

Vue.filter("formatPercent", function (value) {
    let v = formatNumber(value)
    return v === '-' ? '-' : v+'%'
});

export function formatPercent(value) {
    let v = formatNumber(value)
    return v === '-' ? '-' : v+'%'
}

export function formatNumber(value, decimals = false, nullValue = '-') {
    let f
    if(decimals && (value % 1 != 0)) {
        f = numeral(value).format("0,0.00")
    } else {
        f = numeral(value).format("0,0")
    }
    return f === '0' ? nullValue : f;
}

export function formatCurrency(value) {
    let n = numeral(value).format("0,0.00")
    return (n == '' || n == 0.00) ? '-' : (n+' '+window.localStorage.getItem('currency'))
}

Vue.filter("formatCurrency", function (value) {
    let n = numeral(value).format("0,0.00")
    return (n == '' || n == 0.00) ? '-' : (n+' '+window.localStorage.getItem('currency'))
});

Vue.filter('oneLinerFromProperty', function(value, property) {
    if(!value) { return '' }
    return [...new Set(value.map(obj => obj[property]))].join(', ')
})

export function oneLinerFromProperty(value, property) {
    if(!value) { return '' }
    return [...new Set(value.map(obj => obj[property]))].join(', ')
}

Vue.filter("formatPhone", function (value, plus = true) {
    if(!value) return ''
    const regex = /[^0-9+]/g;
    let res = value.toString().replace(/\s+/g, '')
    res = res.replace(regex, '')
    const firstChar = res.charAt(0)
    if(plus) {
        switch(firstChar) {
            case '8':
                res = '+7'+res.substring(1)
                break;
            case '+':
                break;
            default:
                res = '+'+res

        }
    }
    if(!plus) {
        if(firstChar === '+') res = res.substring(1)
    }
    return res
})

export function formatPhone(value, plus = true, eightForSeven = false) {
    if(!value) return ''
    const regex = /[^0-9+]/g;
    let res = value.toString().replace(/\s+/g, '')
    res = res.replace(regex, '')
    const firstChar = res.charAt(0)
    if(plus) {
        switch(firstChar) {
            case '8':
                res = '+7'+res.substring(1)
                break;
            case '+':
            case '0':
                break;
            default:
                res = '+'+res

        }
    }
    if(!plus) {
        if(firstChar === '+') res = res.substring(1)
    }
    if(eightForSeven) {
        if (res.length && res.charAt(0) === '8') {
            res = '7' + res.slice(1)
        }
    }
    return res
}

Vue.filter("filterTeeth", function (value) {
    if(value === 'Н') { return 'НЧ' }
    if(value === 'В') { return 'ВЧ' }
    return value
})

export function filterTeeth(value) {
    if(value === 'Н') { return 'НЧ' }
    if(value === 'В') { return 'ВЧ' }
    return value
}

Vue.filter("formatFileSize", function (value) {
    if(value <= 0) return 0
    var i = Math.floor(Math.log(value) / Math.log(1024))
    return (value / Math.pow(1024, i)).toFixed(1) * 1 + ' ' + ['b', 'Kb', 'Mb', 'Gb', 'Tb'][i]
});

export function formatFileSize(value) {
    if(value <= 0) return 0
    var i = Math.floor(Math.log(value) / Math.log(1024))
    return (value / Math.pow(1024, i)).toFixed(1) * 1 + ' ' + ['b', 'Kb', 'Mb', 'Gb', 'Tb'][i]
}

export const delay = ms => new Promise(res => setTimeout(res, ms))

export function formatHours(value) {
    if(!value) return ''
    const hours = Math.floor(value)
    const minutes = Math.round((value - hours) * 60)
    let str = ''
    if(hours > 0) str += hours + i18n.global.t('hour_short')
    if(minutes > 0) {
        if(str) str += ' '
        str += minutes + i18n.global.t('min_shortest')
    }
    return str
}
