export default {
    add_call_task: "Zəng tapşırığı əlavə et",
    add_to_funnel: "Hunidə əlavə et",
    add_to_funnel_came_and_repeated: "'Gəldi və təkrarlandı' hunisinə əlavə et",
    all_days: "Bütün günlər",
    amo_deal_id: "Amo müqavilə ID",
    api_key: "API açarı",
    applications_and_appeals: "Müraciətlər və şikayətlər",
    are_you_sure_you_want_to_delete_task: "Siz həqiqətən tapşırığı silmək istəyirsiniz?",
    ask_for_confirmation_response_from_patient: "Həstədən təsdiq cavabı soruş",
    attach_file: "Fayl əlavə et",
    binotel: "Binotel",
    binotel_integration: "Binotel ilə inteqrasiya",
    binotel_integration_instructions: "Dentist Plus-u Binotel ilə birləşdirmək üçün:<br>1. Binotel-də hesab yaradın<br>2. Binotel dəstəyinə növbəti webhook-u quraşdırmağı xahiş edən məktub yazın ki<b>API CALL SETTINGS</b> və <b>API CALL COMPLETED</b>:<br><span class='text-primary'>{url}</span><br>3. Aşağıda klinika heyətindən Binotel funksiyasını istifadə edəcək şəxsləri qeyd edin:",
    binotel_key: "Binotel açarı",
    binotel_secret: "Binotel gizli açarı",
    binotel_internal: "Binotel daxili telefon",
    button_title: "Düymənin adı",
    call_from_patient: "Xəstədən zəng",
    call_from_phone: "Nömrədən zəng",
    call_noun: "Zəng",
    callee: "Kimə zəng edilir",
    cancel_sending: "Göndərməni ləğv et",
    cancellation_response: "Səfəri ləğv etmək üçün cavab verin",
    change_status: "Statusu dəyiş",
    client_comment: "Müştərinin şərhi",
    close_the_integration_for_this_branch: "Bu filial üçün inteqrasiyanı bağlayın",
    confirmation_response: "Səfərinizi təsdiqləmək üçün cavab verin",
    copied: "Kopyalandı",
    copy_to_clipboard: "Buferə kopyala",
    create_application_on_call: "Zənglər üçün avtomatik müraciət yarat",
    create_card_automatically: "Avtomatik kart yaradın",
    create_lead: "Müraciət yarat",
    create_lead_on_incoming_messages: "Naməlum nömrədən gələn whatsapp mesajları üçün müraciət yarat",
    crm: "CRM",
    curator_assigned: "Kurator təyin edildi",
    deadline: "Son tarix",
    deal: "Sövdələşmə",
    do_not_create_duplicates: "Dublikat yaratmayın",
    do_not_create_duplicates_on_call: "Zəng üçün aktiv müraciət varsa, 'Qeydiyyat' statusuna qədər dublikat yaratmayın",
    do_not_show_anymore: "Bir daha göstərmə",
    feedback: "Rəy",
    feedbacks: "Rəylər",
    follow: "Keçid et",
    for_came_from_lead_funnel: "'Sorğu və müraciətlər' hunisindən gələnlər üçün",
    for_came_on_first_visit: "İlk ziyarətə gələnlər üçün",
    for_came_to_consultation: "Məsləhətləşməyə gələnlər üçün",
    for_incoming: "Gələnlər üçün",
    for_missed: "Qaçırılanlar üçün",
    for_outcoming: "Geden zənglər üçün",
    funnel_came_and_repeated: "Huni: Ulaşdı və Təkrarlandı",
    generate_key: "Açar yaradın",
    get_gift: "Hədiyyə al",
    has_overdue_tasks: "Vaxtı keçmiş tapşırıqları var",
    has_tasks: "Tapşırıqları var",
    has_today_task: "Bu gün üçün tapşırıqları var",
    if_lead_has_no_responsible_then_assign_to_this_person_on_first_save: "Müraciətin cavabdehi yoxdursa, ilk dəfə saxlayanda bu şəxsi təyin edin",
    inprogress: "İşlənir",
    insert_script_one_time_instruction: "Bu skripti saytda <head> bölməsinə yerləşdirin",
    insert_widget_button_instruction: "Düymənin yerləşdirilməli olduğu yerə bu kodu daxil edin",
    instruction: "Təlimat",
    integration_key: "İnteqrasiya açarı",
    internal_phone: "Daxili telefon",
    lead_channel: "Müraciət kanalı",
    leave_comment: "Şərh yaz",
    manually_selected_user: "Əllə seçilmiş istifadəçi",
    mark_as_completed: "Tamamlandı kimi qeyd et",
    mark_as_finished: "Bitmiş kimi qeyd et",
    mark_as_in_progress: "İşdə kimi qeyd et",
    move_to_funnel_came_and_repeated: "'Gəldi və təkrarlandı' hunisinə keçir",
    my_deals: "Mənim müqavilələrim",
    my_tasks: "Mənim tapşırıqlarım",
    no_rating: "Reytinq yoxdur",
    nobody: "Heç kim",
    one_patient_one_lead: "Bir xəstə - bir müraciət",
    open_whatsapp: "Whatsapp-ı aç",
    outgoing_calls_through: "Çıxış zəngləri vasitəsilə",
    overdue: "Gecikmiş",
    patient_already_has_active_deal: "Xəstədə artıq aktiv bir müqavilə var: {dealId}",
    patient_has_no_future_visits_continue: "Xəstənin gələcək viziti tapılmadı. Yenə davam edilsin?",
    patient_not_derived: "Xəstə müəyyən edilmədi",
    patient_not_derived_from_phone: "{phone} nömrəsindən zəng - xəstə müəyyən edilmədi",
    plan_treatments: "Müalicə planları",
    planned: "Planlaşdırılıb",
    publish: "Dərc et",
    published: "Dərc olunub",
    rating: "Reytinq",
    recall: "Yenidən zəng et",
    recall_on_lead: "'{title}' müraciətinə yenidən zəng et",
    record_myself: "Özümü qeyd et",
    record_on_visit: "Qəbul üçün qeyd et",
    recorded_on_visit: "Qəbula qeyd olunub",
    responsible: "Cavabdeh",
    responsible_on_call: "Zəngdə cavabdeh",
    responsible_on_other_leads_not_created_manually: "Əllə yaradılmayan digər müraciətlər üçün cavabdeh",
    sales_funnel: "Satış hunisi",
    search_by_name: "Ad üzrə axtarış",
    search_by_phone: "Telefon üzrə axtarış",
    see_later: "Sonra bax",
    sipuni: "Sipuni",
    sipuni_integration_instructions: "Dentist Plus-ı Sipuni ilə birləşdirmək üçün:<br>1. Sipuni-də hesab yaradın<br>2. Sipuni idarə panelində API bölməsində inteqrasiya açarı yaradın və 'Zəng nömrəsi' və 'ATS-də hadisələr' xidmətlərini qoşun, həmçinin 'ATS-də hadisələr' bölməsində 'Skripti qəbul edən URL' olaraq aşağıdakı linki daxil edin:<br><span class='text-primary'>{url}</span><br>3. Klinikada Sipuni funksiyasını istifadə edəcək istifadəçiləri və digər sahələri doldurun:",
    soon: "Tezliklə",
    stage: "Mərhələ",
    stages: "Mərhələlər",
    start_period: "Başlama müddəti",
    system_number: "Sistem nömrəsi",
    task: "Tapşırıq",
    task_date: "Tapşırıq tarixi",
    task_type: "Tapşırıq növü",
    tasks_and_calls: "Tapşırıqlar və zənglər",
    tilda_integration_instructions: "Tilda saytınızdakı formalar üçün webhook almaq və Dentist Plus-da müraciətlər yaratmaq üçün:<br/>1. Tilda idarə panelində Sayt Ayarları → Formalar → Webhook bölməsinə keçin<br/>2. Webhook URL sahəsinə <span class='text-primary'>{url}</span> daxil edin<br>3. API METHOD sahəsində 'HEADER' seçin, 'Field name' sahəsinə 'API-KEY' yazın və 'API açarı' sahəsinə aşağıda yarada biləcəyiniz açarı daxil edin.<br/>4. Əlavə ayarlarda 'Cookies göndər' funksiyasını aktiv edin.<br/>5. Aşağıdakı sahələr sistemdə avtomatik olaraq doldurulacaq: <b>ad, telefon, email, şərh</b>.",
    treatment_plan_signed: "Müalicə planı imzalandı",
    treatment_refused: "Müalicədən imtina",
    treatment_started: "Müalicə başladı",
    turn_on_chat_panel: "Söhbət panelini aç",
    uis: "UIS",
    uis_integration_instructions: "Dentist Plus-ı UIS ilə birləşdirmək üçün:<br>1. UIS-də hesab yaradın<br>2. UIS idarə panelində müvafiq istifadəçinin (məsələn, Administrator) parametrlərini açın və API açarı funksiyasını aktiv edin<br/>3. 'Bildirişlər' bölməsində 2 bildiriş yaradın: zəng gözləməsi və zəngin tamamlanması üçün. Hər iki halda HTTP bildiriş metodunu aktiv edin və URL sahəsinə aşağıdakı linki daxil edin:<br><span class='text-primary'>{url}</span><br>4. Klinikada UIS funksiyasını istifadə edəcək işçilərin virtual nömrələrini daxil edin:",
    unpublish: "Dərcdən çıxar",
    use_common_name_for_documents_for_all_branches: "Bütün filiallar üçün sənədlər üçün ümumi adı istifadə edin",
    user_add_id: "Müəllif",
    view: "Baxış",
    visit_confirm: "Qəbulun təsdiqi",
    visit_source: "Qəbulun mənbəyi",
    without_tasks: "Tapşırıq olmadan",
    write_in_messenger: "Mesajda yaz",
    you_also_can_use_link: "Siz həmçinin formaya birbaşa keçid edə bilərsiniz",
}
