export default {
    primaryKey: "id",
    model: 'client_document',
    modaltitle: 'document',
    order: 'id',
    sort: 'desc',
    deletemessage: 'are_you_sure_you_want_to_delete_document',
    showTotalRecords: true,
    actions: [
        "add",
        "excel",
        "edit",
        "delete"
    ],
    fields: [
        {
            id: 'id',
            title: 'id',
            table: true,
            sortable: true
        },
        {
            id: 'uploaded',
            title: 'upload_date',
            table: true,
            sortable: true,
            tabletdclass: "order-mob-0",
            format_datetime: true,
        },
        {
            id: 'original',
            title: 'file',
            table: true,
            sortable: true,
            unavailableonedit: true,
            form: {
                type: "file",
                filetype: "document_image",
                sizelimit: 10,
                multiple: true,
                required: true
            },
            validation_id: 'original.0',
            tdcomponent: "PatientsDatatableTDDocumentFile"
        },
        {
            id: 'size',
            title: 'size',
            table: true,
            sortable: true,
            format_filesize: true
        },
        {
            id: 'description',
            title: 'description',
            table: true,
            form: {
                type: "textarea",
                required: true
            }
        },
    ]

}
