<template>
  <b-modal id="patient-canban-modal"
           @show="onShow"
           @hide="onHide"
           hide-footer
           modal-class="modal-xxl"
           size="xl"
           :title="`${$t('card')} #${patientCanban ? patientCanban.id : ''}`"
           centered>
    <div v-if="loaded && patientCanban && patient" class="modal-grid">
      <!--      <a class="pointer text-danger d-inline-flex align-items-center mb-3"-->
      <!--         v-if="rights.includes('crmtask_delete')"-->
      <!--         @click="deleteCard">-->
      <!--        <TrashSvg class="svg-red svg-size-md mr-2"/>{{ $t('delete') }}-->
      <!--      </a>-->
      <div class="modal-card">
        <div class="form-group">
          <label class="form-label mb-0">{{ $t('status') }}</label>
          <div class="font-weight-bold" :style="`color: ${statusObj.color};`">{{ $t(statusObj.title) }}</div>
        </div>
        <div>
          <label class="form-label mb-0">{{ $t('responsible') }}</label>
          <div>
            <OneFieldEdit modelName="staff"
                          @input="oneFieldSave"
                          v-model="patientCanban.responsible"/>
          </div>
        </div>
      </div>

      <div class="modal-card">
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label class="form-label mb-0">{{ $t('creation_date') }}</label>
              <div>{{ formatDateTime(patientCanban.created_at) }}</div>
            </div>
            <div class="form-group">
              <label class="form-label mb-0">{{ $t('who_created') }}</label>
              <div>{{ patientCanban.author ? patientCanban.author.full_name : '-' }}</div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label class="form-label mb-0">{{ $t('updated_date') }}</label>
              <div>{{ formatDateTime(patientCanban.updated_at) }}</div>
            </div>
            <div class="form-group">
              <label class="form-label mb-0">{{ $t('who_updated') }}</label>
              <div>{{ patientCanban.updater ? patientCanban.updater.full_name : '-' }}</div>
            </div>
          </div>
        </div>
      </div>

      <div class="modal-card overflow-scroll nowrap modal-grid-item-w-100">
        <div class="d-flex flex-nowrap">
          <button v-for="l in patientCanbanStatuses"
                  class="btn-themed btn-themed-outline btn-themed-squared mr-2 lead-status-btn"
                  :style="`border-color: ${l.color}; color: ${l.color};`"
                  @click="changeStatus(l.id)"
                  :key="`le-${l.id}`">
            {{ $t(l.title) }}
          </button>
          <div style="width: 12px;">&nbsp;</div>
        </div>
      </div>

      <div class="modal-card">
        <div class="modal-card-title">{{ $t('patient') }}</div>
        <div class="row row-narrow">
          <div class="col-auto">
            <PatientAvatar :user="patient"/>
          </div>
          <div class="col">

            <div>
              <router-link :to="`/patients/${patient.id}/visits`">
                {{ patient.full_name }}
              </router-link>
              <div class="ml-2 d-inline-block">
                <a class="circle mr-2 pointer"
                   :style="`background-color: ${t.color}`"
                   v-for="(t, tind) in patient.tags"
                   :title="t.title"
                   v-b-tooltip.hover
                   :key="`made-tag-${tind}`"/>
              </div>
            </div>

            <div class="d-inline-flex align-items-center">
              <div class="d-inline-flex align-items-center"
                   v-if="patient.mphone && !rights.includes('hide_patient_phone')">
                <OutgoingCallElement :phone="patient.mphone"
                                     color="black"
                                     class="mphone"/>
                <a class="ml-3"
                   :href="`tel:${patient.mphone}`"
                   v-b-tooltip.hover
                   :title="$t('call')">
                  <PhoneSvg class="svg-primary"/>
                </a>
                <WhatsappTelegramIcon :patient-id="patient.id"
                                      :phone="patient.phone"/>

              </div>
            </div>
          </div>
        </div>

        <div class="row mt-2">
          <div class="col-md-6">


            <div class="mt-1 form-group" v-if="patient.activity_status">
              <label class="form-label mb-0">{{ $t('activity') }}</label>
              <div>{{ $t(patient.activity_status) }}</div>
            </div>

            <div v-if="patient.clientStatus" class="form-group">
              <label class="form-label mb-0">{{ $t('status') }}</label>
              <div>{{ $t(patient.clientStatus) }}</div>
<!--            <span class="badge"-->
<!--                  :class="'badge-'+patient.clientStatus">-->
<!--              {{ $t(patient.clientStatus) }}-->
<!--            </span>-->

              <div>
                <button v-if="patient"
                        class="btn-themed px-0 w-100 btn-themed-squared mt-3 sm"
                        @click="recordPatient">
                  {{ $t('record_on_visit') }}
                </button>
              </div>

            </div>

          </div>

          <div class="col-md-6">
            <div class="form-group">
              <label class="form-label mb-0">{{ $t('treating_doctor') }}</label>:
              <div>
                <OneFieldEdit modelName="doctor"
                              v-model="patientCanban.patient.doctor"
                              @input="oneFieldSave"
                              :autocompleteAdditionalParams="{ 'blocked': 0 }"/>
              </div>
            </div>
            <div>
              <label class="form-label mb-0">{{ $t('curator') }}</label>:
              <div>
                <OneFieldEdit modelName="curator"
                              @input="oneFieldSave"
                              v-model="patientCanban.patient.curator"/>
              </div>
            </div>
          </div>
        </div>

        <AutosizeTextArea v-model="patientCanban.comment"
                          :label="$t('comment_to_deal')"/>

      </div>

      <div class="modal-card">
        <div>
          <div class="modal-card-title">
            <div class="position-relative">
              <div class="row align-items-center no-gutters">
                <div class="col">
                  {{ $t('treatment_plan') }}
                </div>
                <div class="col-auto font-weight-normal">
                  <ServiceMultiSelect :dblclick="false"
                                      v-if="planTreatments.length"
                                      model="planning_treatment"
                                      class="mb-0 sm position-absolute"
                                      style="top: 50%; right: 0; transform: translateY(-50%); z-index: 3; min-width: 200px"
                                      :prepopulated-options="planTreatments"
                                      :multiple="false"
                                      title="message"
                                      @input="planTreatmentAttached"
                                      :dbsearch="false"
                                      valueId="planning_id"
                                      v-model="treatmentPlanToBeAdded"/>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-plan-treatment-grid">
            <div class="modal-subcard"
                 v-for="treatment_plan in patientCanban.treatment_plans"
                 :key="`t-plan-${treatment_plan.id}`">
              <div class="row align-items-start">
                <div class="col">{{ treatment_plan.message }}</div>
                <div class="col-auto">
                  <a class="pointer" @click="removePlanTreatment(treatment_plan)">
                    <CloseSvg class="svg-size-sm svg-gray"/>
                  </a>
                </div>
              </div>
              <div v-if="treatment_plan.signed_at" class="text-primary small">{{ $t('signed') }}</div>
              <div v-else class="small text-muted">{{ $t('not_signed') }}</div>
              <div v-for="visit in treatment_plan.visits"
                   :key="`vis-${visit.id}`"
                   class="visit-line">
                <div class="d-flex justify-content-between align-items-center">
                  <div>
                    {{ visit.fullTitle }} ({{ $t(visit.manual_status) }})
                  </div>

                  <span class="circle" :class="`status-${visit.manual_status}`"></span>
                </div>
                <div class="d-flex justify-content-between align-items-center">
                  <div v-if="visit.doctor" class="small text-muted">{{ $t('doctor') }}: {{ visit.doctor.full_name }}</div>
                  <div>
                  <span class="text-muted small">{{ $t('plan') }}</span> {{ formatNumber(visit.discountedPrice) }} / <span class="text-muted small">{{ $t('paid') }}</span> {{ formatNumber(visit.paidRecordsSum) }}
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-subcard modal-grid-item-w-100">
              <div class="row">
                <div class="col-md-4">
                  <div>
                    <label class="form-label mb-0">{{ $t('first_visit') }}</label>
                    <div>{{ visitString(patient.firstVisit, 'firstVisitDoctor') }}</div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div>
                    <label class="form-label mb-0">{{ $t('last_visit') }}</label>
                    <div>{{ visitString(patient.lastVisit, 'lastVisitDoctor') }}</div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div>
                    <label class="form-label mb-0">{{ $t('next_visit') }}</label>
                    <div>{{ visitString(patient.nextVisit, 'nextVisitDoctor') }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>

      </div>

      <div class="modal-card">
        <b-row class="mb-3 row-narrow">
          <b-col>
            <button class="btn btn-themed btn-themed-outline btn-themed-squared w-100 nowrap"
                    @click="addRecallTask">
              {{ $t('recall') }}
            </button>
          </b-col>
          <b-col>
            <button class="btn btn-themed btn-themed-outline btn-themed-squared w-100 nowrap"
                    @click="addTask">
              {{ $t('add_task') }}
            </button>
          </b-col>
        </b-row>
        <div class="mt-3 mb-3" v-if="patientCanban.tasks && patientCanban.tasks.length">
          <TaskTeaser v-for="(task, tInd) in patientCanban.tasks"
                      :show-info="false"
                      @loadTasks="load"
                      :key="`task-${task.id}-${tInd}`"
                      :task="task"/>
        </div>
      </div>

      <div class="modal-card">
        <b-tabs nav-class="sub-menu"
                content-class="pt-4"
                class="w-100">
          <b-tab active :title="$t('comments')">
            <GeneralCommentsBlock :object="patientCanban"
                                  :black-label="false"
                                  model="patient_canban_comment"
                                  parent-column="patient_canban_id"/>
          </b-tab>
          <b-tab :title="$t('live_feed')">
            <PatientsCommentsList :patient="patient"
                                  :checked="patientCommentTypes.map(x => x.id)"
                                  :show-images="false"/>
          </b-tab>
          <!--          <b-tab :title="$t('plan_treatments')" lazy>-->
          <!--            <DataTable :options="options"-->
          <!--                       module="patients"-->
          <!--                       :params="{ client_id: patient.id }"/>-->
          <!--          </b-tab>-->
        </b-tabs>
      </div>

      <div class="modal-footer-buttons modal-card no-print text-right modal-grid-item-w-100">
        <button class="btn-themed align-middle btn-themed-squared gray mr-3"
                @click="deleteCard">
          {{ $t('delete') }}
        </button>
        <button :disabled="loading"
                @click="save"
                class="btn-themed align-middle btn-themed-squared">
          {{ $t('save') }}
        </button>
      </div>
    </div>
  </b-modal>
</template>

<script setup>
import { patientCommentTypes } from "@/dictionaries/dictionary"
import { useStore } from "vuex"
import { computed, getCurrentInstance, onMounted, ref, watch } from "vue"
import { CRMService, EloquentService } from "@/services/api.service"
import { appendFormdata, copyObject } from "@/extensions/prototypes/prototypes"
import WhatsappTelegramIcon from "@/components/pages/patients/WhatsappTelegramIcon.vue"
import PhoneSvg from "@/assets/svg-vue/header/phone_t.svg.vue"
import CloseSvg from "@/assets/svg-vue/general/close.svg.vue"
import OutgoingCallElement from "@/components/parts/patients/OutgoingCallElement.vue"
import OneFieldEdit from "@/components/parts/general/OneFieldEdit.vue"
import AutosizeTextArea from "@/components/form/AutosizeTextArea.vue"
import TaskTeaser from "@/components/parts/header/TaskTeaser.vue"
import GeneralCommentsBlock from "@/components/parts/general/GeneralCommentsBlock.vue"
import moment from 'moment'
import { useRouter } from "vue-router"
import {formatDateTime, formatNumber} from "@/extensions/filters/filters"
import PatientsCommentsList from "@/components/parts/patients/comment/PatientsCommentsList.vue"
import ServiceMultiSelect from "@/components/form/ServiceMultiSelect.vue"
import PatientAvatar from "@/components/parts/patients/PatientAvatar.vue"
// import DataTable from "@/components/parts/general/DataTable.vue"
// import options from "@/data/patients/plan_treatment"
// import TrashSvg from "@/assets/svg-vue/general/trash_tr.svg.vue"

const store = useStore()
const router = useRouter()

const patientCanban = computed(() => store.state.crm.patientCanban)
const rights = computed(() => store.state.auth.rights)
const patient = computed(() => patientCanban.value?.patient)
const counter = computed(() => store.state.datatable.dataTableCounter)
const user = computed(() => store.state.auth.user)
const patientCanbanStatuses = computed(() => store.state.auth.patientCanbanStatuses)
const statusObj = computed(() => patientCanbanStatuses.value.find(l => l.id === patientCanban.value.status_id))

let bvModal = null
let i18n = null
let noty = null
const loaded = ref(false)
const loading = ref(false)
const planTreatments = ref([])
const treatmentPlanToBeAdded = ref(null)

onMounted(() => {
  const instance = (getCurrentInstance())
  bvModal = instance?.ctx?._bv__modal
  i18n = instance?.ctx?.$i18n
  noty = instance?.appContext?.app?.noty
})

const changeStatus = async (status_id) => {
  try {
    await CRMService.canbanStatusMoved(patientCanban.value.id, { status_id })
    store.commit('incrementDataTableCounter')
    await load()
  } catch (e) {
    console.log(e)
  }
}

const saveCanban = async (data, refresh = true) => {
  data.patient_id = patientCanban.value.patient_id
  if(!data.status_id) data.status_id = patientCanban.value.status_id
  if(!data.treatment_plans?.length) data.treatment_plans = ''
  const formData = appendFormdata(new FormData(), data)
  try {
    await EloquentService.update('patient_canban', patientCanban.value.id, formData, false, false)
    if(refresh) {
      store.commit('incrementDataTableCounter')
      await load()
    }
  } catch (e) {
    console.log(e)
  }
}

const load = async () => {
  if(!patientCanban.value) return
  const updated = await EloquentService.show('patient_canban', patientCanban.value.id)
  store.commit('setPatientCanban', copyObject(updated.data))
  loaded.value = true
}

const loadPlanTreatments = async () => {
  const res = await EloquentService.dataTable('plan_treatment', 1, {
    pagination: 999,
    condition: {
      client_id: patient.value.id
    }
  })
  planTreatments.value = res.data.result.data
}

const onShow = async () => {
  await load()
  await loadPlanTreatments()
  if(!patientCanbanStatuses.value.length) {
    await store.dispatch('getPatientCanbanStatuses')
  }
}

const onHide = () => {
  loaded.value = false
}

const addTask = () => {
  store.commit('setModule', 'crm')
  store.commit('setViewModel', 'task')
  store.commit('setSelectedModel', {
    task_type: 'task',
    importance: 'medium',
    patient_canban_id: patientCanban.value.id,
    phone: patient.value.mphone,
    patient_id: patient.value.id,
    patient: patient.value,
  })
  store.commit('setShowModal', true)
}

const addRecallTask = () => {
  store.commit('setModule', 'crm')
  store.commit('setViewModel', 'task')
  store.commit('setSelectedModel', {
    patient_canban_id: patientCanban.value.id,
    task_type: 'call',
    // title: i18n.t('recall_on_lead', { title: lead.value.title }),
    deadline: moment().add(1, 'days').format('YYYY-MM-DD HH:mm'),
    start: moment().add(1, 'days').format('YYYY-MM-DD HH:mm'),
    patient_id: patient.value.id,
    patient: patient.value,
    phone: patient.value.mphone || '',
    importance: 'high',
    user: user.value,
    user_id: user.value.id
  })
  store.commit('setShowModal', true)
}

const recordPatient = async () => {
  router.push({ name: 'Calendar' }).catch(() => {})
  store.commit('setPatientFromCall', copyObject(patient.value))
  bvModal.hide('patient-canban-modal')
}

const visitString = (visit, doctor) => {
  if(!visit) return '-'
  const doctorName = patient.value[doctor] ? `, ${patient.value[doctor].full_name}` : ''
  return `${visit.readableStart}${doctorName}`
}

const oneFieldSave = () => {
  setTimeout(() => {
    save(false)
  }, 20)
}

const save = async (hide = true) => {
  loading.value = true
  console.log(patientCanban.value.treatment_plans)
  await saveCanban({
    doctor_id: patientCanban.value.patient?.doctor?.id || null,
    curator_id: patientCanban.value.patient?.curator?.id || null,
    treatment_plans: patientCanban.value.treatment_plans.map(obj => obj.planning_id),
    responsible_user_id: patientCanban.value.responsible?.id || null,
    comment: patientCanban.value.comment
  }, hide)
  loading.value = false
  if(hide) {
    bvModal.hide('patient-canban-modal')
    noty.info(i18n.t('success_message'))
  }
}

const planTreatmentAttached = (val) => {
  if(!val) return
  if(patientCanban.value.treatment_plans.find(obj => Number(obj.planning_id) === Number(val.planning_id))) return
  patientCanban.value.treatment_plans.push(val)
  treatmentPlanToBeAdded.value = null
  oneFieldSave()
}

const removePlanTreatment = (val) => {
  if(!val) return
  patientCanban.value.treatment_plans = patientCanban.value.treatment_plans.filter(obj => Number(obj.planning_id) !== Number(val.planning_id))
  oneFieldSave()
}

const deleteCard = async () => {
  if(!confirm(i18n.t('confirm_deletion'))) return
  loading.value = true
  const res = await EloquentService.delete('patient_canban', patientCanban.value.id)
  store.commit('setPatientCanban', null)
  if(!res.data) return
  loading.value = false
  bvModal.hide('patient-canban-modal')
  store.commit('incrementDataTableCounter')
}

watch(counter, () => {
  load()
})
</script>
