<template>
  <span>
    <PatientCallingModal :patients="patient"
                         ref="patientCallingModal"
                         :phone="phone"
                         :leadId="leadId"/>
    <ApproveTechSupportLoginModal :superUserRequestingAccessId="superUserRequestingAccessId"
                                  :userIdToImitateLogin="userIdToImitateLogin"/>
  </span>
</template>

<script>
import JwtService from "@/services/jwt.service"
import { mapState } from "vuex"
import PatientCallingModal from "@/components/parts/crm/PatientCallingModal"
import initEcho from "@/extensions/plugins/echo"
import ApproveTechSupportLoginModal from "@/components/parts/settings/ApproveTechSupportLoginModal.vue"

export default {
  name: "LaravelEcho",
  components: {
    ApproveTechSupportLoginModal,
    PatientCallingModal
  },
  computed: {
    ...mapState({
      useProxy: state => state.auth.useProxy,
      clinic: state => state.auth.clinic,
      user: state => state.auth.user,
      rights: state => state.auth.rights,
    }),
    ppoSettings() {
      return this.clinic.ppo_settings ? JSON.parse(this.clinic.ppo_settings) : { binotel: [], sipuni: [], create_application_on_call: 0 }
    },
    binotelUsers() {
      return this.ppoSettings.binotel ?? []
    },
    binotelUsersIds() {
      return this.binotelUsers.map(u => u?.id ?? u)
    },
    sipuniUsers() {
      return this.ppoSettings.sipuni ?? []
    },
    sipuniUsersIds() {
      return this.sipuniUsers.map(u => u?.id ?? u)
    },
    uisUsers() {
      return this.ppoSettings.uis ?? []
    },
    uisUsersIds() {
      return this.uisUsers.map(u => u?.id ?? u)
    },
    vendorUsers() {
      if(this.vendor === 'binotel') return this.binotelUsers
      if(this.vendor === 'sipuni') return this.sipuniUsers
      if(this.vendor === 'uis') return this.uisUsers
      return []
    },
    vendorUserIds() {
      return this.vendorUsers.map(u => u?.id ?? u)
    }
  },
  data() {
    return {
      patient: null,
      phone: null,
      leadId: null,
      vendor: null,
      echo: null,
      superUserRequestingAccessId: null,
      userIdToImitateLogin: null
    }
  },
  mounted() {
    this.echo = initEcho(this.useProxy === true)
    this.echo.options.auth.headers.Authorization = 'Bearer '+JwtService.getToken()
    // this.echo.channel(`clinic1843`) - for public channels
    this.echo.private(`clinic.${this.clinic.id}`)
        .listen('.messageSent', (data) => {
          console.log(data)
          this.processMessage(data)
        }).error(err => console.log(err))

    this.echo.private(`user.${this.user.id}`)
        .listen('.userMessageSent', (data) => {
          console.log('userMessageSent', data)
          this.processUserMessage(data)
        }).error(err => console.log(err))
  },
  methods: {
    async processMessage(data) {
      if(!data || !data.message || !data.message.type) return
      if(data.message.type === 'refreshCalendar') {
        this.$store.commit('setRefreshCalendar', true)
        return
      }
      if(data.message.type === 'trustMeStatus') {
        this.$store.commit('incrementDataTableCounter')
        return
      }
      this.vendor = data.message.provider
      if(!this.vendorUserIds.includes(this.user.id)) return
      if(data.message.type === 'callStarted') {
        const internalPhone = data.message.internalPhone
        if(internalPhone) {
          const usersWithThisInternal = this.vendorUsers.filter(u => (u?.tel ?? null) === internalPhone)
          if(usersWithThisInternal.length) {
            const userIds = usersWithThisInternal.map(u => u.id)
            if(!userIds.includes(this.user.id)) return // means it is addressed to specific user(s)
          }
        }
        const employeeId = data.message.employeeId
        if(employeeId) {
          const usersWithThisEmployeeId = this.vendorUsers.filter(u => (u?.employeeId ?? null) === employeeId)
          if(usersWithThisEmployeeId.length) {
            const userIds = usersWithThisEmployeeId.map(u => u.id)
            if(!userIds.includes(this.user.id)) return // means it is addressed to specific user(s)
          }
        }
        this.patient = data.message.patient
        this.phone = data.message.phone
        this.leadId = data.message.leadId || null
        this.$store.commit('setShowCallModal', true)
        await this.$nextTick()
        this.$refs.patientCallingModal?.show()
      }
      if(data.message.type === 'callEnded' && data.message.responded) {
        this.$store.commit('setShowCallModal', false)
        this.vendor = null
      }
    },
    processUserMessage(data) {
      if(data.message.type === 'visitTiming') {
        this.$store.commit('setMyOpenedVisit', data.message.data)
      }
      if(data.message.type === 'accessRequest') {
        this.superUserRequestingAccessId = data.message.data.superUserId
        this.userIdToImitateLogin = data.message.data.userIdToImitateLogin
        this.$bvModal.show('approve-tech-support-login-modal')
      }
      if(data.message.type === 'accessRequestFinished') {
        this.$bvModal.hide('approve-tech-support-login-modal')
      }
    }
  }
}
</script>
