<template>
  <div>
    <ServiceMultiSelect :label="$t('patient')"
                        :dblclick="false"
                        model="patient"
                        :multiple="false"
                        title="full_name"
                        :required="true"
                        :dbsearch="true"
                        v-model="patient"/>
    <div v-if="object.patient_id">
      <a v-if="existingCanbanCard && onePatientOneLead"
           @click="existingCanbanClicked"
           class="text-danger d-block mb-3 small pointer text-underlined">
        {{ $t('patient_already_has_active_deal', { dealId: existingCanbanCard.id }) }}
      </a>
      <SelectGroup :options="planTreatments"
                   variant="white"
                   title="message"
                   valuekey="planning_id"
                   :label="$t('treatment_plan')"
                   :translate="false"
                   :showlabel="true"
                   v-model="object.treatment_plan_id"/>
    </div>
  </div>
</template>

<script setup>

import ServiceMultiSelect from "@/components/form/ServiceMultiSelect"
import {ref, watch, computed, getCurrentInstance } from "vue"
import SelectGroup from "@/components/form/SelectGroup.vue"
import { CRMService, EloquentService } from "@/services/api.service"
import { useStore } from "vuex"
import {copyObject} from "@/extensions/prototypes/prototypes"

const props = defineProps({
  object: {
    type: Object
  },
})

const store = useStore()
const instance = (getCurrentInstance())
const patient = ref(null)
const existingCanbanCard = ref(null)
const planTreatments = ref([])

patient.value = props.object.patient
if(patient.value) {
  loadPlanTreatments(patient.value.id)
}

const onePatientOneLead = computed(() => store.getters.getPPOAttribute('one_patient_one_lead'))

watch(() => props.object.patient_id, async (patientId) => {
  await loadPlanTreatments(patientId)
  props.object.treatment_plan_id = null
})

const loadPlanTreatments = async (patientId) => {
  const res = await CRMService.getPlanTreatments({
    client_id: {
      id: 'client_id',
      type: 'equals',
      condition: patientId
    }
  })
  planTreatments.value = res.data.data
}

watch(() => patient.value, (val) => {
  props.object.patient_id = val.id
  if(onePatientOneLead.value) {
    EloquentService.dataTable('patient_canban', 1, {
      pagination: 999,
      sort: 'desc',
      order: 'id',
      condition: { patient_id: val.id }
    }).then(res => {
      existingCanbanCard.value = res.data?.result?.data?.[0] || null
    }).catch(err => {
      existingCanbanCard.value = null
      console.error(err)
    })
  }
})

const existingCanbanClicked = () => {
  if(!existingCanbanCard.value) return
  store.commit('setPatientCanban', copyObject(existingCanbanCard.value))
  const bvModal = instance?.ctx?._bv__modal
  bvModal?.show('patient-canban-modal')
}
</script>
