export default {
    f37u78: "Форма 037/У-88",
    f43kaz: "Форма 043/КАЗ",
    f43rus: "Форма 043/РФ",
    f43ukr: "Форма 043/У",
    f58kaz: "Форма 058/КАЗ",
    accept_another_payment: "Прийняти ще оплату",
    activity: "Активність",
    add_complex: "Додати комплекс",
    add_diagnosis: "Додати діагноз",
    add_question: "Додати питання",
    add_service: "Додати послугу",
    add_tooth_jaw: "Додати зуб / щелепу",
    add_visit: "Додати візит",
    added_by: "Ким додано",
    additional_discount: "Додаткова знижка",
    age: "Вік",
    agreement: "Згода",
    akt_of_services_for_period: "Акт наданих послуг за період",
    akt_print: "Друк акта",
    allergic_reactions: "Алергічні реакції",
    allergy_diagnosis: "Алергологічний діагноз",
    anamnesis: "Стоматологічний анамнез",
    applied: "Застосовано",
    applied_complex: "Застосований комплекс",
    apply_to_teeth: "Застосувати до зубів",
    apply_to_whole_tooth: "Застосувати до всього зуба",
    apply_treatment_template: "Застосувати шаблон лікування",
    are_you_sure_to_set_plan: "Ви впевнені, що хочете прив'язати картку лікування до плану - заповнені поля картки можуть бути перезаписані?",
    are_you_sure_you_want_to_delete_document: "Ви впевнені, що хочете видалити документ?",
    are_you_sure_you_want_to_delete_payment: "Ви впевнені, що хочете видалити оплату?",
    are_you_sure_you_want_to_delete_scan: "Ви впевнені, що хочете видалити скан?",
    are_you_sure_you_want_to_delete_status: "Ви впевнені, що хочете видалити статус?",
    are_you_sure_you_want_to_delete_transaction: "Ви впевнені, що хочете видалити транзакцію?",
    are_you_sure_you_want_to_delete_user: "Ви впевнені, що хочете видалити користувача?",
    are_you_sure_you_want_to_delete_users: "Ви впевнені, що хочете видалити користувачів?",
    ask_result_on_saving: "Запитати результат при збереженні",
    assistant: "Асистент",
    attach_new_user: "Прив'язати нового користувача",
    author: "Автор",
    available: "Доступно",
    baby_teeth: "Молочні зуби",
    balance_refill: "Поповнення депозиту",
    balance_writeoff: "Списання депозиту",
    birthdays: "Дні народження",
    bite: "Прикус",
    bottom_baby_teeth: "Нижні молочні зуби",
    bottom_jaw: "Нижня щелепа",
    bottom_molars: "Нижні корінні зуби",
    by_doctors: "По лікарям",
    by_complexes: "За комплексами",
    call: "Подзвонити",
    came: "Прийшов",
    came_from: "Звідки прийшов",
    can_be_paid_with_bonuses: "Можна сплатити бонусами",
    cancelled: "Відмінено",
    cancel_initiator: "Ініціатор відміни",
    card: "Картка",
    card_number: "Номер картки",
    card_transfer: "Переказ на картку",
    cash: "Готівка",
    cashless: "Безготівка",
    choose_document_type_and_its_parameters: "Виберіть тип документа і його параметри",
    choose_patient: "Виберіть пацієнта",
    choose_signer: "Виберіть підписанта",
    choose_treatment_template: "Виберіть шаблон лікування",
    client: "Пацієнт",
    client_document: "Документ пацієнта",
    client_id: "Пацієнт",
    client_note: "Примітка",
    client_service: "Клієнтський сервіс",
    code: "Код",
    color: "Колір",
    combine_user_note: "Вибравши пацієнта і натиснувши \"об'єднати\", ви перенесете всі дані по візитах, оплатах, діагнозами і тд в картку вибраного пацієнта. Картка поточного пацієнта буде видалена. Процедуру об'єднання не можна буде скасувати.",
    comment_to_phone: "Коментар до телефону",
    comment_to_phone_2: "Коментар до додаткового телефону",
    complaints: "Скарги",
    complex: "Комплексний",
    complexes: "Комплекси",
    condition: "Стан",
    confirm_tag_deletion: "Ви впевнені, що хочете видалити тег?",
    confirmed: "Підтверджена",
    confirmed_male: "Затверджений",
    contract: "Договір",
    contract_agreement: "Договір згоди",
    contract_general: "Загальний договір",
    contract_print: "Друк договору",
    contract_services: "Договір надання послуг",
    consultation: "Консультація",
    create_diagnosis: "Створити діагноз",
    creation_date: "Дата створення",
    date: "Дата",
    date_add: "Створено",
    date_from: "Дата від",
    date_of_birth: "Дата народження",
    date_to: "Дата до",
    dates_not_selected: "Дати не вибрані",
    debt: "Борг",
    debt_cancellation: "Відміна боргу",
    delete_complex: "Видалити комплекс",
    delete_visit: "Видалити візит",
    department_code: "Код підрозділу",
    deposit: "Депозит",
    description: "Опис",
    desired_date_from: "Бажана дата до",
    desired_date_to: "Бажана дата від",
    desired_time_from: "Бажаний час від",
    desired_time_to: "Бажаний час до",
    detailed_information: "Детальна інформація",
    diagnoses: "Діагнози",
    diagnosis: "Діагноз",
    did_not_come: "Не прийшов",
    discount: "Знижка",
    disease_anamnez: "Анамнез захворювання (розвиток справжнього захворювання)",
    disease_development: "Розвиток наявного захворювання",
    disease_history: "Історія хвороби",
    diseases_passed_and_current: "Перенесені та супутні захворювання",
    doctor: "Лікар",
    document: "Документ",
    document_number: "Номер документа",
    document_type: "Тип документа",
    drag_file_here_or_select: "Перенесіть сюди файл або оберіть",
    enter_the_title: "Уведіть назву",
    epikriz: "Епікриз",
    examination_plan: "План обстеження",
    f043: "Ф 043",
    family_account: "Сімейний рахунок",
    female: "Жінка",
    female_short: "Жін",
    fill_anamnez_from_previous_visits: "Заповнити анамнез з попередніх візитів",
    fill_balance: "Поповнити депозит",
    filling: "Поповнення",
    finish_visit: "Завершити прийом",
    fio: "ПІБ",
    first_visit: "Первинний візит",
    fiscal_cheque: "Фіскальний чек",
    for_female_patients: "Для пацієнток",
    formula: "Формула",
    foto_link_note: "Крім завантаження фотографії, можна вказати публічне посилання на зображення",
    from_account: "З рахунку",
    full_card: "Повна картка",
    gender: "Стать",
    general_info: "Основна інформація",
    hygiene_index: "Індекс гігієни",
    iin: "ІПН",
    in_chair: "У кріслі",
    in_process: "В процесі",
    include_in_f043: "Включити до форми 043",
    include_in_f058: "Включити до форми 058",
    inspection: "Зовнішній огляд",
    insurance: "Страховка",
    invoice_print: "Друк рахунку",
    importance: "Важливість",
    important: "Важливо",
    inn: "ІПН",
    is_deleted: "Видалено",
    issue_date: "Дата видачі",
    issued_by: "Ким видано",
    jaws: "Щелепи",
    kpu_index: "Індекс КПУ",
    last_visit: "Останній візит",
    left_due: "Залишок",
    life_anamnez: "Анамнез життя",
    link: "Посилання",
    link_to_call_record: "Посилання на запис розмови",
    listen: "Послухати",
    live_feed: "Жива стрічка",
    lost: "Загублений",
    make_payment: "Виконати оплату",
    male: "Чоловік",
    male_short: "Чол",
    manual_status: "Ручний статус",
    medication: "Лікування",
    middle_name: "По батькові",
    mkb_code: "Код МКХ",
    move_to_visit: "Перейти до візиту",
    mphone: "Телефон",
    ndfl_statement: "Довідка НДФЛ",
    ndfl_statement_new: "Довідка НДФЛ (нова)",
    ndfl_statement_xml: "Довідка НДФЛ (XML)",
    new: "Новий",
    new_patient: "Первинний пацієнт",
    next_visit: "Наступний візит",
    no_attachment: "Немає вкладень",
    no_complexes: "Немає комплексів",
    no_teeth_in_the_record: "У запису не вказано ні одного зуба",
    norm: "Норма",
    not_confirmed: "Не підтверджена",
    note: "Примітка",
    notes: "Примітки",
    objectively: "Об'єктивно",
    on_week: "На тиждень",
    or_write_other_reason: "або вкажіть іншу причину",
    oral_cavity: "Ротова порожнина",
    ortodentic_formula: "Ортодонтична формула",
    ortodentic_formula_ru: "Ортодонтична формула (РФ)",
    other: "Інше",
    overpayment: "Переплата",
    paid: "Сплачено",
    passive: "Пасивний",
    passport: "Паспорт",
    passport_data: "Паспортні данні",
    passport_number: "Номер паспорта",
    password_validation_error: "Пароль має містити не менше 8 символів, принаймні одну велику літеру, принаймні одну цифру та принаймні один спеціальний символ",
    patient: "Пацієнт",
    patient_condition_id: "Стан",
    patient_deleted: "Пациієнт видалений",
    patient_fio: "ПІБ пацієнта",
    patient_has_important_notes: "У пацієнта важливих позначок: {cnt}",
    patient_number: "Номер пацієнта",
    patient_phone: "Телефон пацієнта",
    patient_source: "Звідки прийшов",
    payment: "Оплата",
    payment_accepted: "Оплата прийнята",
    payment_date: "Дата оплати",
    payment_form: "Форма оплати",
    payment_history: "Історія оплат",
    payment_processing: "Проведення оплати",
    payment_type: "Спосіб оплати",
    payments: "Оплати",
    permanent_teeth: "Постійні зуби",
    personal_data: "Персональні дані",
    pesel: "PESEL",
    phone_2: "Додатковий телефон",
    pin: "Закріпити",
    pinned: "Закріплено",
    plan_fact: "План / Факт",
    plan_notification: "Запланувати повідомлення",
    planned_result: "Планований результат",
    planned_send_date: "Планована дата відправки",
    planned_services: "Плановані процедури",
    planned_treatment: "Плановане лікування",
    preliminary: "Попередній",
    price_price: "Прайс",
    prices_price: "Прайси",
    print_empty: "Друк незаповненої",
    process: "Провести",
    questionnaire: "Анкета",
    quick_card: "Швидка картка",
    receipt: "Квитанція",
    recommendations: "Рекомендації",
    record: "Запис",
    record_not_available: "Запис недоступний",
    recorded: "Записаний",
    refresh_prices: "Оновити ціни",
    region: "Регіон",
    regular_client: "Постійний пацієнт",
    rejected: "Відмовився",
    reopen_visit: "Повторно відкрити прийом",
    representative: "ПІБ представника",
    representative_address: "Адреса представника",
    representative_document: "Реквізити документа, що засвідчує право представляти пацієнта",
    representative_document_number: "Номер документа представника",
    representative_document_type: "Тип документа представника",
    representative_id: "Представник із списку пацієнтів",
    representative_inn: "ІПН представника",
    representative_noun: "Представник",
    representative_passport: "Номер паспорта представника",
    representative_passport_data: "Паспортні дані представника",
    representative_phone: "Телефон представника",
    request_snils: "Запит СНІЛС",
    request_snils_note: "Отримання СНІЛС в ЕГІСЗ - для цього потрібно заповнити ПІБ пацієнта, стать, дату народження та паспортні дані (тип, серія, номер, дата випуску та текст випуску)",
    restore: "Відновити",
    result: "Результат",
    results_not_found: "Результатів не знайдено",
    results_of_xray_and_lab_research: "Дані рентгенівських, лабораторних досліджень",
    save_as_template: "Зберегти як шаблон",
    save_as_treatment_template: "Зберегти як шаблон лікування",
    save_and_close: "Зберегти і закрити",
    scan: "Знімок",
    scans: "Знімки",
    select_document_type: "Оберіть тип документа",
    select_visit: "Оберіть візит",
    selected_teeth: "Вибрані зуби",
    send: "Відправити",
    send_mail: "Відправити лист",
    send_marketing: "Надсилати маркетингові розсилки",
    send_message: "Відправити повідомлення",
    send_notifications: "Надсилати повідомлення",
    send_password_to_patient: "Відправити пароль пацієнту",
    send_try: "Спроба відправлення",
    series: "Серія",
    service: "Послуга",
    services_payment_from: "Оплата наданих послуг від",
    set_the_amount: "Вкажіть кількість",
    size: "Размір",
    sms_available: "Доступне СМС",
    sms_sending: "Відправка СМС",
    snils: "СНІЛС",
    sorting_instructions: "Перетягніть рядки мишею, щоб розташувати їх у потрібному порядку",
    standard: "Стандарт",
    start_treatment: "Почати лікування",
    start_visit: "Почати прийом",
    stopped: "Зупинено",
    subjectively: "Суб'єктивно",
    sum_with_discount: "Сума зі знижкою",
    superadministrator: "Суперадміністратор",
    surname: "Прізвище",
    short_name: "Коротка назва",
    symbols_amount: "Кількість символів",
    tags_for_contract: "Теги для автоматичного виведення інформації:",
    tags_for_document: "Теги для автоматичного виведення інформації:",
    teeth: "Зуби",
    teeth_card: "Зубна картка",
    termometry: "Термометрія",
    there_were_no_payments: "Оплат поки що не було",
    this_phone_is_in_system: "Даний номер вже є в базі",
    to_account: "На рахунок",
    to_pay_with_bonus: "Сплатити бонусами",
    to_whom: "Кому",
    tonometry: "Тонометрія",
    tooth: "Зуб",
    topic: "Тема",
    treatment_template: "Шаблон лікування",
    tooth_is_assigned: "Зуб не может бути видалений - до нього вже прив'язані послуги або діагнози",
    tooth_formula: "Зубна формула",
    top_baby_teeth: "Верхні молочні зуби",
    top_jaw: "Верхня щелепа",
    top_molars: "Верхні корінні зуби",
    to_add_diagnoses_you_need_to_select_teeth: "Щоб додати діагнози, необхідно обрати зуби",
    to_add_services_you_need_to_select_teeth: "Щоб додати діагнози, необхідно обрати зуби",
    total_paid: "Всього сплачено",
    total_paid_for_treatment_plan: "Оплачено послуг із плану лікування",
    total_services_selected: "Всього обрано послуг",
    total_to_pay: "Разом до сплати",
    total_with_discount: "Разом зі знижкою",
    transfer_between_patient_accounts: "Переказ між рахунками пацієнта",
    transfer_whole_amount: "Переказати всю суму",
    treating_doctor: "Лікуючий лікар",
    treatment: "Лікування",
    treatment_card: "Картка лікування",
    treatment_complete: "Лікування завершено",
    treatment_plan: "План лікування",
    un: "шт.",
    unify: "Об'єднати",
    unify_card: "Об'єднати картку",
    unpaid: "Не оплачено",
    unpin: "Відкріпити",
    upload_date: "Дата завантаження",
    used_medication: "Лікарські препарати, що приймаються",
    user_attached_to_family_account: "Користувач прив'язаний до сімейного рахунку",
    users_attached_to_user_family_account: "Пацієнти, прив'язані до сімейного рахунку користувача",
    visit: "Візит",
    visit_date: "Дата візиту",
    visit_description_doctor: "Опис лікаря",
    visit_duration: "Тривалість візиту (у хв)",
    visit_finished: "Прийом закінчено",
    visit_in_progress: "Йде прийом",
    visit_started: "Прийом розпочато",
    visit_time: "Час візиту",
    visits: "Візити",
    waiting: "Очікує",
    waiting_list: "Лист очікування",
    with_formula: "З формулою",
    without_payment: "Без оплати",
    write_reason: "Вкажіть причину",
    xray: "Рентген",
    years: "Років",
    you_need_to_select_teeth: "Необхідно обрати зуби",
    zipcode: "Поштовий індекс",
}
