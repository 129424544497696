export const reportPopulatedAdapter = (obj) => {
	obj.report_name = 'ndfl'
	return replaceNulls(obj)
}

function replaceNulls(obj) {
	for (const key in obj) {
		if (obj[key] === null) {
			obj[key] = ""
		} else if (typeof obj[key] === "object" && obj[key] !== null) {
			replaceNulls(obj[key])
		}
	}
	return obj
}
