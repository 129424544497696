<template>
  <div class="clinic-data page pb-5"
       v-if="form">
    <InputGroup v-model="form.name"
                :label="$t('title')"/>
    <CheckboxGroup fid="useGenericNameForBranches"
                   v-if="branches"
                   :label="$t('use_common_name_for_documents_for_all_branches')"
                   :checkboxvalue="1"
                   v-model="form.use_common_name_for_branches"/>
    <InputGroup v-model="form.address"
                :label="$t('address')"/>
    <InputGroup v-model="form.email"
                :label="$t('email')"/>
    <InputGroup v-model="form.phone"
                :label="$t('phone')"/>
<!--    <InputGroup v-model="form.clinic_inn"-->
<!--                :label="$t('clinic_inn')"/>-->
<!--    <b-row>-->
<!--      <b-col lg="4">-->
<!--        <InputGroup v-model="form.license_num"-->
<!--                    :label="$t('license_num')"/>-->
<!--      </b-col>-->
<!--      <b-col lg="4">-->
<!--        <DateGroup v-model="form.license_issue_date"-->
<!--                   :label="$t('license_issue_date')"/>-->
<!--      </b-col>-->
<!--      <b-col lg="4">-->
<!--        <InputGroup v-model="form.license_issue_body"-->
<!--                    :label="$t('license_issue_body')"/>-->
<!--      </b-col>-->
<!--    </b-row>-->

    <div v-if="branches" class="bg-gray py-3 px-4 mb-2">

      <div class="mb-2">{{ $t('branch_contacts') }}: <span>{{ branch.title }}</span></div>

      <InputGroup v-model="branchForm.title"
                  @input="branchChanged = true"
                  :label="$t('title')"/>
      <b-row>
        <b-col md="6">
          <InputGroup v-model="branchForm.email"
                      @input="branchChanged = true"
                      :label="$t('email')"/>
        </b-col>
        <b-col md="6">
          <InputGroup v-model="branchForm.phone"
                      @input="branchChanged = true"
                      :label="$t('phone')"/>
        </b-col>
      </b-row>
      <InputGroup v-model="branchForm.address"
                  @input="branchChanged = true"
                  :label="$t('branch_address')"/>
      <b-row>
        <b-col md="4" v-if="isRussia">
          <InputGroup v-model="branchForm.inn"
                      @input="branchChanged = true"
                      :label="$t('branch_inn')"/>
        </b-col>
        <b-col md="4" v-if="isRussia">
          <InputGroup v-model="branchForm.ogrn"
                      @input="branchChanged = true"
                      :label="$t('ogrn')"/>
        </b-col>
        <b-col md="4" v-if="isRussia">
          <InputGroup v-model="branchForm.kpp"
                      @input="branchChanged = true"
                      :label="$t('kpp')"/>
        </b-col>
        <b-col md="12" v-if="isKazakhstan">
          <InputGroup v-model="branchForm.bin"
                      @input="branchChanged = true"
                      :label="$t('bin')"/>
        </b-col>
        <b-col md="4">
          <InputGroup v-model="branchForm.license_num"
                      @input="branchChanged = true"
                      :label="$t('license_num')"/>
        </b-col>
        <b-col md="4">
          <DateGroup v-model="branchForm.license_issue_date"
                      @input="branchChanged = true"
                      :label="$t('license_issue_date')"/>
        </b-col>
        <b-col md="4">
          <InputGroup v-model="branchForm.license_issue_body"
                      @input="branchChanged = true"
                      :label="$t('license_issue_body')"/>
        </b-col>
        <b-col md="6">
          <InputGroup v-model="branchForm.legal_name"
                      @input="branchChanged = true"
                      :label="$t('legal_name')"/>
        </b-col>
        <b-col md="6">
          <InputGroup v-model="branchForm.legal_address"
                      @input="branchChanged = true"
                      :label="$t('legal_address')"/>
        </b-col>
        <b-col md="12">
          <InputGroup v-model="branchForm.director_fio"
                      @input="branchChanged = true"
                      :label="$t('director_fio')"/>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6">
          <InputGroup v-model="branchForm.whatsapp"
                      @input="branchChanged = true"
                      placeholder="https://wa.me/1XXXXXXXXXX"
                      :label="$t('whatsapp')"/>
        </b-col>
        <b-col md="6">
          <InputGroup v-model="branchForm.telegram"
                      @input="branchChanged = true"
                      placeholder="https://t.me/xxxxxxx"
                      :label="$t('telegram')"/>
        </b-col>
        <b-col md="6">
          <InputGroup v-model="branchForm.instagram"
                      placeholder="https://www.instagram.com/xxxxxxx/"
                      @input="branchChanged = true"
                      label="Instagram"/>
        </b-col>
        <b-col md="6">
          <InputGroup v-model="branchForm.tiktok"
                      placeholder="https://www.tiktok.com/xxxxxxx"
                      @input="branchChanged = true"
                      label="Tiktok"/>
        </b-col>
        <b-col md="12">
          <AutosizeTextArea v-model="branchForm.document_header"
                            @input="branchChanged = true"
                            :label="$t('document_header')"/>
        </b-col>
      </b-row>
      <div v-if="mapLoaded" class="form-label mb-1">
        {{ $t('location_on_map') }}
      </div>
      <yandex-map :settings="yaSettings"
                  class="ya-map"
                  zoom="12"
                  @map-was-initialized="mapLoaded = true"
                  @click="mapClicked"
                  :coords="mapCenterCoords">
        <ymap-marker v-if="branchForm.x_coord && branchForm.y_coord"
                     marker-id="markerId"
                     :coords="[branchForm.x_coord,branchForm.y_coord]"/>
      </yandex-map>

    </div>

<!--    <InputGroup v-model="form.ogrn"-->
<!--                :label="$t('ogrn')"/>-->
    <AutosizeTextArea v-model="form.plan_treatment_text"
                      :label="$t('plan_treatment_text')"/>
    <AutosizeTextArea v-model="form.akt_text"
                      :label="$t('akt_text')"/>
    <div class="mt-5">
      <button class="btn btn-themed btn-themed-squared"
              @click="save">
        {{ $t('save') }}
      </button>
    </div>
  </div>
</template>

<script>
import InputGroup from '@/components/form/InputGroup'
import { mapGetters, mapState } from "vuex"
import { ClinicService, EloquentService } from "@/services/api.service"
import AutosizeTextArea from "@/components/form/AutosizeTextArea"
import DateGroup from "@/components/form/DateGroup.vue"
import store from "@/store"
import { yandexMap, ymapMarker } from 'vue-yandex-maps'
import { countries } from "@/dictionaries/dictionary"
import CheckboxGroup from "@/components/form/CheckboxGroup.vue"

export default {
  name: "SettingsContacts",
  head() {
    return {
      title: this.$t('contacts'),
    }
  },
  components: {
    CheckboxGroup,
    DateGroup,
    AutosizeTextArea,
    InputGroup,
    yandexMap,
    ymapMarker
  },
  data() {
    return {
      form: null,
      branchForm: null,
      branchAddress: '',
      branchChanged: false,
      yaSettings: {
        apiKey: 'ea26376a-3779-49bf-aa73-5a3a63cdc136',
        lang: 'ru_RU',
        coordorder: 'latlong',
        enterprise: false,
        version: '2.1'
      },
      mapLoaded: false
    }
  },
  computed: {
    ...mapState({
      clinic: state => state.auth.clinic,
      branches: state => state.auth.branches,
      currentBranchId: state => state.auth.branch,
    }),
    ...mapGetters(['countryId']),
    branch() {
      return this.branches.find(x => x.id == this.currentBranchId)
    },
    mapCenterCoords() {
      if(this.branchForm.x_coord && this.branchForm.y_coord) return [this.branchForm.x_coord, this.branchForm.y_coord]
      const countryId = this.countryId
      if(countryId === countries.UKRAINE) return [50.450441, 30.523550] // Ukraine, Kyiv
      if(countryId === countries.RUSSIA) return [55.755864, 37.617698] // Russia, Moscow
      if(countryId === countries.KAZAKHSTAN) return [51.128201, 71.430429] // Kazakhstan, Astana
      if(countryId === countries.KYRGYZSTAN) return [42.875969, 74.603701] // Kyrgyzstan, Bishkek
      if(countryId === countries.AZERBAIJAN) return [40.4093, 49.8671] // Azerbaijan, Baku
      return [55.755864, 37.617698]
    },
    isRussia() {
      return this.countryId === countries.RUSSIA
    },
    isKazakhstan() {
      return this.countryId === countries.KAZAKHSTAN
    }
  },
  methods: {
    async save() {
      let formData = new FormData()
      formData = this.appendFormdata(formData, this.form)
      try {
        let res = await EloquentService.update('clinic_contact',this.form.id, formData)
        if(this.branchChanged) {
          await ClinicService.updateBranchContacts(this.branchForm)
          await store.dispatch('init')
        }
        this.$noty.info(this.$t('success_message'))
        this.form = res.data
      } catch (e) {
        this.$noty.error(e?.response?.data?.message || this.$t('error'))
      }
    },
    getInfo() {
      ClinicService.getContactInfo().then(res => {
        this.form = res.data.info
        this.branchForm = res.data.branch
        // this.branchAddress = res.data.branch.address
      })
    },
    mapClicked(e) {
      const coords = e.get('coords')
      this.branchForm.x_coord = coords[0]
      this.branchForm.y_coord = coords[1]
      this.branchChanged = true
    }
  },
  mounted() {
    this.getInfo()
  }
}
</script>

<style scoped lang="scss">
.clinic-data {
  max-width: 600px;
}
.bg-gray {
  border-radius: 5px;
}
.ya-map {
  width: 100%;
  height: 300px;
}
</style>
