import { patientAdapter } from "@/adapters/patients/patient"
import { countries } from "@/dictionaries/dictionary"

export default {
    primaryKey: "id",
    model: 'patient',
    order: 'lname',
    modalsize: 'xl',
    sort: 'asc',
    showTotalRecords: true,
    prepareParams: ['id'],
    actionscomponent: 'PatientDatatableActions',
    additionalFormComponent: 'PatientsAdditionalFormComponent',
    adapter: patientAdapter,
    checks: true,
    deletemessage: 'are_you_sure_you_want_to_delete_user',
    actions: [
        "add",
        "excel",
        "edit",
        "delete"
    ],
    rights: {
        edit: "patients_update",
        delete: "patients_delete",
    },
    fields: [
        {
            id: 'id',
            title: 'id',
            table: true,
            sortable: true
        },
        {
            id: 'full_name',
            title: 'fio',
            table: true,
            sortable: 'lname',
            tabletdclass: "order-mob-0",
            tdcomponent: 'PatientsDatatableTDName',
        },
        {
            id: 'lname',
            title: 'surname',
            table: false,
            filter: true,
            form: {
                classes: 'col-md-4',
                type: "text",
                required: true,
                tabs: ['general_info', 'quick_card', 'full_card'],
            }
        },
        {
            id: 'fname',
            title: 'name',
            table: false,
            filter: true,
            form: {
                classes: 'col-md-4',
                type: "text",
                required: true,
                tabs: ['general_info', 'quick_card', 'full_card'],
            }
        },
        {
            id: 'mname',
            title: 'middle_name',
            table: false,
            filter: true,
            form: {
                classes: 'col-md-4',
                type: "text",
                tabs: ['general_info', 'quick_card', 'full_card'],
            }
        },
        {
            id: 'gender',
            title: 'gender',
            table: false,
            filter: true,
            filter_list_select: true,
            form: {
                classes: 'col-md-4',
                type: "select",
                select_type: "list",
                options: {
                    "0": { id: "0", title: "male" },
                    "1": { id: "1", title: "female" },
                },
                tabs: ['general_info', 'quick_card', 'full_card'],
            }
        },
        {
            id: 'visit_date',
            title: 'visit_date',
            table: false,
            filter: true,
            filter_date: true
        },
        {
            id: 'date_of_birth',
            title: 'date_of_birth',
            table: false,
            form: {
                classes: 'col-md-4',
                type: "date",
                tabs: ['general_info', 'quick_card', 'full_card'],
            }
        },
        {
            id: 'mphone',
            title: 'phone',
            table: true,
            sortable: true,
            filter: true,
            form: {
                type: "text",
                classes: 'col-md-4',
                required: true,
                component: 'PatientsDatatableFormMPhone',
                tabs: ['general_info', 'quick_card', 'full_card'],
            },
            right_excludes: 'hide_patient_phone',
            format_phone: true,
        },
        {
            form: {
                classes: 'col-md-12',
                type: 'separator',
                tabs: ['general_info', 'full_card'],
            }
        },
        {
            id: 'card',
            title: 'card',
            table: true,
            sortable: true,
            filter: true,
            form: {
                classes: 'col-md-4',
                type: "text",
                required: true,
                component: "PatientsDatatableFormCard",
                tabs: ['general_info', 'quick_card', 'full_card'],
            }
        },
        {
            id: 'address',
            title: 'address',
            table: false,
            form: {
                classes: 'col-md-4',
                type: "text",
                component: "PatientsDatatableFormAddress",
                tabs: ['passport_data', 'full_card'],
            }
        },
        {
            id: 'region_egisz_id',
            title: 'region',
            table: false,
            form: {
                classes: 'col-md-4',
                type: "select",
                translate: false,
                tabs: ['passport_data', 'full_card'],
                conditionFunc(store) {
                    return store.state.auth.rights.includes('egisz_view')
                }
            }
        },
        {
            id: 'zip_code',
            title: 'zipcode',
            table: false,
            form: {
                classes: 'col-md-4',
                type: "text",
                tabs: ['passport_data', 'full_card'],
            }
        },
        {
            id: 'age',
            title: 'age',
            table: true,
            sortable: 'date_of_birth',
            tdcomponent: 'PatientsDatatableTDAge',
        },
        {
            id: 'email',
            title: 'email',
            table: false,
            filter: true,
            form: {
                classes: 'col-md-4',
                type: "text",
                tabs: ['general_info', 'full_card'],
            }
        },
        {
            id: 'mphone2',
            title: 'phone_2',
            form: {
                type: "text",
                classes: 'col-md-4',
                component: 'PatientsDatatableFormMPhone2',
                tabs: ['general_info', 'full_card'],
            },
            right_excludes: 'hide_patient_phone',
        },
        {
            id: 'advance_calculated',
            title: 'balance',
            table: true,
            sortable: true,
            format_currency: true
        },
        {
            id: 'debt_calculated',
            title: 'debt',
            table: false,
            sortable: true,
            format_currency: true
        },
        {
            form: {
                classes: 'col-md-12',
                tabs: ['full_card'],
                title: 'passport_data',
                type: 'separator'
            }
        },
        {
            id: 'document_type_id',
            title: 'document_type',
            table: false,
            form: {
                classes: 'col-md-4',
                type: "select",
                translate: false,
                tabs: ['passport_data', 'full_card'],
            }
        },
        {
            id: 'passport_series',
            title: 'series',
            table: false,
            form: {
                classes: 'col-md-4',
                type: "text",
                tabs: ['passport_data', 'full_card'],
            }
        },
        {
            id: 'passport',
            title: 'document_number',
            table: false,
            form: {
                classes: 'col-md-4',
                type: "text",
                tabs: ['passport_data', 'full_card'],
            }
        },
        {
            id: 'passport_given_by',
            title: 'issued_by',
            table: false,
            form: {
                classes: 'col-md-4',
                type: "text",
                tabs: ['passport_data', 'full_card'],
            }
        },
        {
            id: 'passport_issue_date',
            title: 'issue_date',
            table: false,
            form: {
                classes: 'col-md-4',
                type: "date",
                tabs: ['passport_data', 'full_card'],
            }
        },
        {
            id: 'passport_dep_code',
            title: 'department_code',
            table: false,
            form: {
                classes: 'col-md-4',
                type: "text",
                tabs: ['passport_data', 'full_card'],
                conditionFunc(store) {
                    return store.state.auth.clinic.country_id == countries.RUSSIA
                }
            }
        },
        {
            id: 'iin',
            title: 'iin',
            table: false,
            filter: true,
            form: {
                classes: 'col-md-4',
                type: "text",
                tabs: ['passport_data', 'quick_card', 'full_card'],
                conditionFunc(store) {
                    return store.state.auth.clinic.country_id == countries.KAZAKHSTAN
                }
            }
        },
        {
            id: 'taxcode',
            title: 'inn',
            table: false,
            filter: true,
            form: {
                classes: 'col-md-4',
                type: "text",
                tabs: ['passport_data', 'quick_card', 'full_card'],
                conditionFunc(store) {
                    return store.state.auth.clinic.country_id == countries.RUSSIA
                }
            }
        },
        {
            id: 'snils',
            title: 'snils',
            table: false,
            filter: true,
            form: {
                classes: 'col-md-4',
                type: "text",
                tabs: ['passport_data', 'quick_card', 'full_card'],
                component: 'PatientsDatatableFormSnils',
                conditionFunc(store) {
                    return store.state.auth.clinic.country_id == countries.RUSSIA
                }
            }
        },
        {
            id: 'pesel',
            title: 'pesel',
            table: false,
            filter: true,
            form: {
                classes: 'col-md-4',
                type: "text",
                tabs: ['passport_data', 'full_card'],
                conditionFunc(store) {
                    return store.state.auth.clinic.country_id == countries.POLAND
                }
            }
        },
        // {
        //     form: {
        //         classes: 'col-md-12',
        //         tabs: ['full_card'],
        //         title: 'representative_noun',
        //         type: 'separator'
        //     }
        // },
        {
            id: 'representative',
            title: 'representative_id',
            table: false,
            form: {
                classes: 'col-md-4',
                type: "autocomplete",
                autocomplete_title: 'full_name',
                model: "patient",
                tab: 'representative',
            }
        },
        {
            id: 'representative_relation',
            title: 'representative_relation',
            table: false,
            form: {
                classes: 'col-md-4',
                type: "select",
                translate: false,
                tab: 'representative',

            }
        },
        {
            id: 'representative_fio',
            title: 'representative',
            table: false,
            form: {
                classes: 'col-md-4',
                type: "text",
                tab: 'representative',
            }
        },
        {
            id: 'representative_gender',
            title: 'gender',
            table: false,
            filter: true,
            filter_list_select: true,
            form: {
                classes: 'col-md-4',
                type: "select",
                select_type: "list",
                options: {
                    "0": { id: "0", title: "male" },
                    "1": { id: "1", title: "female" },
                },
                tab: 'representative',
            }
        },
        {
            id: 'representative_date_of_birth',
            title: 'date_of_birth',
            table: false,
            form: {
                classes: 'col-md-4',
                type: "date",
                tab: 'representative',
            }
        },
        {
            id: 'representative_phone',
            title: 'representative_phone',
            table: false,
            form: {
                classes: 'col-md-4',
                type: "text",
                tab: 'representative',
            }
        },
        {
            id: 'representative_passport',
            title: 'representative_document',
            table: false,
            form: {
                classes: 'col-md-12',
                type: "text",
                tab: 'representative',
            }
        },
        {
            form: {
                classes: 'col-md-12',
                tab: 'representative',
                title: 'representative_passport_data',
                type: 'separator'
            }
        },
        {
            id: 'representative_document_type_id',
            title: 'document_type',
            table: false,
            form: {
                classes: 'col-md-4',
                type: "select",
                translate: false,
                tab: 'representative',
            }
        },
        {
            id: 'representative_passport_series',
            title: 'series',
            table: false,
            form: {
                classes: 'col-md-4',
                type: "text",
                tab: 'representative',
            }
        },
        {
            id: 'representative_passport_data',
            title: 'document_number',
            table: false,
            form: {
                classes: 'col-md-4',
                type: "text",
                tab: 'representative',
            }
        },
        {
            id: 'representative_passport_issue_date',
            title: 'issue_date',
            table: false,
            form: {
                classes: 'col-md-4',
                type: "date",
                tab: 'representative',
            }
        },
        {
            id: 'representative_passport_dep_code',
            title: 'department_code',
            table: false,
            form: {
                classes: 'col-md-4',
                type: "text",
                tab: 'representative',
                conditionFunc(store) {
                    return store.state.auth.clinic.country_id == countries.RUSSIA
                }
            }
        },
        {
            id: 'representative_passport_given_by',
            title: 'issued_by',
            table: false,
            form: {
                classes: 'col-md-4',
                type: "text",
                tab: 'representative',
            }
        },
        {
            id: 'representative_snils',
            title: 'snils',
            table: false,
            form: {
                classes: 'col-md-4',
                type: "text",
                tab: 'representative',
                conditionFunc(store) {
                    return store.state.auth.clinic.country_id == countries.RUSSIA
                }
            }
        },
        {
            id: 'representative_inn',
            title: 'representative_inn',
            table: false,
            form: {
                classes: 'col-md-4',
                type: "text",
                tab: 'representative',
                conditionFunc(store) {
                    return store.state.auth.clinic.country_id == countries.RUSSIA
                }
            }
        },
        {
            form: {
                classes: 'col-md-12',
                tab: 'representative',
                title: 'representative_address',
                type: 'separator'
            }
        },
        {
            id: 'representative_region_egisz_id',
            title: 'region',
            table: false,
            form: {
                classes: 'col-md-4',
                type: "select",
                translate: false,
                tab: 'representative',
                conditionFunc(store) {
                    return store.state.auth.rights.includes('egisz_view')
                }
            }
        },
        {
            id: 'representative_address',
            title: 'representative_address',
            table: false,
            form: {
                classes: 'col-md-4',
                type: "text",
                tab: 'representative',
            }
        },
        {
            id: 'representative_zip_code',
            title: 'zipcode',
            table: false,
            form: {
                classes: 'col-md-4',
                type: "text",
                tab: 'representative',
            }
        },
        {
            form: {
                classes: 'col-md-12',
                tabs: ['full_card'],
                type: 'separator'
            }
        },
        {
            id: 'discount',
            title: 'discount',
            table: false,
            form: {
                classes: 'col-md-4',
                type: "number",
                tabs: ['general_info', 'full_card'],
            }
        },
        {
            id: 'doctor_id',
            title: 'treating_doctor',
            table: false,
            form: {
                type: "select",
                classes: 'col-md-4',
                select_type: "model",
                tabs: ['general_info', 'full_card'],
                select_data: {
                    "title": "full_name"
                },
            }
        },
        {
            id: 'status_id',
            title: 'status',
            table: false,
            form: {
                classes: 'col-md-4',
                type: "select",
                select_type: "list",
                options: {
                    "2": { id: "2", title: "regular_client" },
                    "3": { id: "3", title: "first_visit" },
                },
                tabs: ['general_info', 'quick_card', 'full_card'],
            }
        },
        {
            id: 'invoiced',
            title: 'invoiced',
            table: true,
            filter: true,
            filter_number: true,
            sortable: true,
            format_currency: true
        },
        {
            id: 'paid',
            title: 'paid',
            table: true,
            filter: true,
            filter_number: true,
            sortable: true,
            format_currency: true
        },
        {
            id: 'debt',
            title: 'debt',
            table: true,
            filter: true,
            filter_number: true,
            sortable: true,
            format_currency: true
        },
        {
            id: 'source',
            title: 'came_from',
            table: true,
            table_show: 'acquisition_source.title',
            sortable: true,
            filter: true,
            filter_model_select: true,
            form: {
                classes: 'col-md-4',
                type: "select",
                select_type: "model",
                select_data: {
                    "title": "title"
                },
                tabs: ['general_info', 'quick_card', 'full_card'],
            }
        },
        {
            id: 'patient_condition_id',
            title: 'condition',
            table: true,
            table_show: 'patient_condition.title',
            filter: true,
            filter_model_select: true,
            form: {
                classes: 'col-md-4',
                type: "select",
                tabs: ['general_info', 'full_card'],
                select_type: "model",
                select_data: {
                    "title": "title"
                },
            }
        },
        {
            id: 'tag_id',
            title: 'tags',
            table: true,
            filter: true,
            sortable: true,
            filter_model_select: true,
            tdcomponent: 'PatientsDatatableTDTags'
        },
        {
            id: 'url',
            title: 'link',
            table: false,
            form: {
                classes: 'col-md-4',
                type: "text",
                tabs: ['general_info', 'full_card'],
            }
        },
        {
            id: 'curator_id',
            title: 'curator',
            table: true,
            table_show: 'curator.full_name',
            sortable: true,
            filter: true,
            filter_model_select: true,
            // filter_model_select_equals: true,
            form: {
                type: "select",
                classes: 'col-md-4',
                select_type: "model",
                tabs: ['general_info', 'full_card'],
                select_data: {
                    "title": "full_name"
                },
            }
        },
        {
            id: 'description',
            title: 'description',
            filter: true,
            form: {
                type: "textarea",
                classes: 'col-md-12',
                tabs: ['general_info', 'full_card'],
            }
        },
        // {
        //     form: {
        //         classes: 'col-md-12',
        //         full_card: true,
        //         type: 'separator'
        //     }
        // },
        {
            id: 'send_notifications',
            title: 'send_notifications',
            table: false,
            form: {
                classes: 'col-md-auto',
                type: "checkbox",
                tabs: ['general_info', 'full_card'],
            }
        },
        {
            id: 'send_marketing',
            title: 'send_marketing',
            table: false,
            form: {
                classes: 'col-md-auto',
                type: "checkbox",
                tabs: ['general_info', 'full_card'],
            }
        },
        {
            id: 'send_to_egisz',
            title: 'send_to_egisz',
            table: false,
            form: {
                classes: 'col-md-auto',
                type: "checkbox",
                tabs: ['general_info', 'full_card'],
                conditionFunc(store) {
                    return store.state.auth.rights.includes('egisz_view')
                }
            }
        },
        {
            id: 'doctor_id',
            title: 'doctor',
            filter: true,
            filter_model_select: true
        },
        {
            id: 'activity_status',
            title: 'activity',
            filter: true,
            filter_list_select: true,
            form: {
                options: {
                    "new": { id: "new", title: "new" },
                    "active": { id: "active", title: "active" },
                    "passive": { id: "passive", title: "passive" },
                    "lost": { id: "lost", title: "lost" },
                }
            }
        },
        {
            id: 'first_visit_date',
            title: 'first_visit_date',
            table: true,
            format_date: true,
            filter: true,
            filter_date: true
        },
        {
            id: 'last_visit_date',
            title: 'last_visit_date',
            table: true,
            format_date: true,
            filter: true,
            filter_date: true
        },
        {
            id: 'blocked',
            title: 'is_deleted',
            filter: true,
            filter_boolean: true
        },
        {
            id: 'age',
            title: 'age',
            filter: true,
            filter_number: true
        },
    ]

}
