export default {
    accepted_by: "Accept",
    additional_income: "Additional Income",
    additional_costs: "Expences",
    admin_statistics_future: "Administrator statistics on new Appointments for future dates",
    administrator_percent: "Administrator's percent",
    administrator_sum: "Administrator's sum",
    apply: "Apply",
    assistant_percent: "Assistant's percent",
    assistant_sum: "Assistant's sum",
    average_cheque: "Average cheque",
    average_conversion_per_doctor_per_day: "Average indicator per doctor per day",
    average_conversion_per_doctor_per_period: "Average indicator per doctor per period",
    balance_amount: "Paid from deposit",
    balance_return: "Deposit return",
    by: "By",
    by_means: "By method of payment",
    by_services: "By Services",
    came_again: "Came again",
    came_among_patients: "Therefrom came",
    cancel_date: "Cancellation date",
    cancel_reason: "Cancellation reason",
    cancelled_visits: "Cancelled appointments",
    cashdesk_report: "Cashdesk report",
    cash_flow_statement: "Cashflow statement",
    conversion: "Conversion",
    cos: "Prime costs",
    costs: "Expences",
    cost_of_materials: "Material cost",
    crm_leads_analytics: "CRM leads analytics",
    curator_id: "Curator's id",
    curator_name: "Curator's name",
    curator_percent: "Curator's percent",
    curator_sum: "Curator's sum",
    daily_report: "Daily report",
    debt_payments: "Debt payment",
    debt_writeoff: "Debt discharge",
    debtor: "Debtor",
    debtors: "Debtors",
    debtors_statement: "Debtors statement",
    details_by_visits: "Details by visits",
    did_not_came_again: "Did not come again",
    did_not_come_among_patients: "Therefrom did not come",
    doctor_percent: "Doctor's interest",
    doctor_load_percent: "Doctor load percent",
    doctor_name: "Doctor",
    doctor_salary: "Doctor's wage",
    doctor_statistics: "Doctors statistics",
    doctor_sum: "Doctors amount",
    doctors_conversion: "Doctors' conversion",
    doctors_load: "Doctors' load",
    duration: "Duration",
    f057: "Form 057",
    first_visit_date: "Date of first visit",
    first_visit_doctor: "Doctor of first visit",
    full_name: "Name",
    general_percent: "Total interest",
    hide_cancelled_visits: "Hide cancelled visits",
    hidden_columns: "Hidden columns",
    income_by_doctors: "Income by Doctors",
    income_by_services: "Income by Services",
    initiator: "Initiator",
    invoiced: "Invoice issued",
    last_visit_date: "Date of last visit",
    last_visit_doctor: "Doctor of last visit",
    last_visit_recommendation: "Last visit recommendations",
    margins: "Gross profit",
    materials_movement_analytics: "Materials movement analytics",
    next_visit_date: "Date of next visit",
    next_visit_doctor: "Doctor of next visit",
    operation_type: "Operation type",
    orthodontist: "Orthodontist",
    orthopedist: "Orthopedist",
    paid_from_account: "Paid from account",
    paid_for: "Paid for",
    paid_invoices: "Paid Invoices",
    patient_id: "Patient id",
    patient_name: "Patient",
    patient_status: "Patient status",
    permanent_among_patients: "Therefrom loyal patients visits",
    pl_statement: "Profit and loss statement",
    plan_treatment_complexes: "Complexes",
    plan_treatments: "Treatment plan",
    previous_visit_date: "Previous visit date",
    price_before_discount: "Amount before Discount",
    primary_among_patients: "Therefrom first-come patients visits",
    primary_records: "Primary - recorded",
    primary_records_came: "Primary - came",
    primary_records_further: "Primary - recorded later",
    primary_records_revenue: "Primary - revenue",
    records: "Records",
    records_count: "Number of visits",
    repeated_records: "Repeated - recorded",
    repeated_records_came: "Repeated - came",
    repeated_records_further: "Repeated - recorded later",
    repeated_records_revenue: "Repeated - revenue",
    report_is_too_heavy_confirm: "The report is too large to be viewed on a page, do you want to export the report to an excel file?",
    report_days_limit: "Reports period limit (days)",
    revenue: "Revenue",
    revenue_per_hour: "Revenue per hour",
    revenues: "Revenues",
    saldo: "Nett",
    sales_funnel_applications: "Sales funnel: Applications",
    sales_funnel_plans: "Sales funnel: Treatment plans",
    services_amount: "Services amount",
    show_completed_visits: "Show completed visits",
    show_deleted_doctors: "Show deleted doctors",
    show_only_new_patients: "Show only new patients",
    source_type: "Source type",
    technic_percent: "Technician's interest",
    technic_sum: "Technician amount",
    therapist: "Basic generalist doctor",
    total_patients: "Number of patients who made an appointment",
    total_primary: "Total primary",
    total_revenue: "Total revenue",
    unpaid_invoices: "Unpaid invoices",
    updated_date: "Updated date",
    visit_id: "Visit Id",
    visit_statistics: "Visit statistics",
    visits_by_source: "Visits by source",
    visits_detailed: "Visits (detailed)",
    where_came_from: "Came from",
    who_cancelled: "Who cancelled",
    who_created: "Who created",
    who_updated: "Who updated",
    with_patient: "With patient",
    worked_days: "Worked days",
    worked_hours: "Worked hours",
}
