<template>
  <aside class="sidebar" :class="{ collapsed: collapsed }">
    <router-link :to="{ name: 'SuperAdminClinics' }"
                 class="d-block sidebar__logo-wrapper">
      <img src="/images/general/logo_short.png" class="sidebar__logo sidebar__logo-short"/>
      <img src="/images/general/logo.png" class="sidebar__logo sidebar__logo-full"/>
    </router-link>
    <MenuToggler view="sidebar"/>
    <div class="sidebar__menu">
      <template v-for="(m, mind) in topmenu">
        <div v-if="!m.right || (m.right && rights.includes(m.right))"
             :key="`topmenu-${mind}`"
             class="side-menu-item">
          <router-link class="sidebar__item" :to="m.to">
            <component :is="m.i" :class="`sidebar__svg-${m.title}`"/>
            <span class="sidebar__title">{{ $t(m.title) }}</span>
          </router-link>
        </div>
      </template>
    </div>
  </aside>
</template>

<script>

import ClinicSvg from '@/assets/svg-vue/sidebar/clinic.svg'
import DollarSvg from '@/assets/svg-vue/sidebar/dollar.svg'
import NoteSvg from '@/assets/svg-vue/sidebar/note.svg'
import MarketingSvg from '@/assets/svg-vue/sidebar/marketing.svg'
import TemplatesSvg from '@/assets/svg-vue/sidebar/template.svg'
import APISvg from '@/assets/svg-vue/sidebar/crm.svg'
import UserSvg from '@/assets/svg-vue/header/user.svg'
import ShieldSvg from '@/assets/svg-vue/sidebar/shield.svg'
import SadSvg from '@/assets/svg-vue/sidebar/sad.svg'
import LogSvg from '@/assets/svg-vue/sidebar/log.svg'
import { mapState } from "vuex"
import MenuToggler from "@/components/parts/header/MenuToggler"

export default {
  name: "SuperAdminSidebar",
  components: {
    MenuToggler,
    ClinicSvg,
    DollarSvg,
    MarketingSvg,
    TemplatesSvg,
    APISvg,
    UserSvg,
    SadSvg,
    LogSvg
  },
  data() {
    return {
      topmenu: [
        { i: ClinicSvg, to: '/superadmin/clinics', title: 'clinics' },
        { i: DollarSvg, to: '/superadmin/payments/list', title: 'payments' },
        { i: NoteSvg, to: '/superadmin/tariffs', title: 'tariffs' },
        { i: MarketingSvg, to: '/superadmin/notifications', title: 'notifications' },
        { i: TemplatesSvg, to: '/superadmin/popups', title: 'popup' },
        { i: APISvg, to: '/superadmin/api', title: 'api' },
      ]
    }
  },
  computed: {
    ...mapState({
      collapsed: state => state.dom.sidebarCollapsed,
      rights: state => state.auth.rights,
      user: state => state.auth.user,
    }),
    role() {
      return this.$store.state.auth.user.role
    },
    userId() {
      return this.user ? this.user.id : null
    },
  },
  mounted() {
    if(['manager', 'tech_specialist'].includes(this.role)) {
      this.topmenu = [
        { i: ClinicSvg, to: '/superadmin/clinics', title: 'clinics' },
      ]
    }
    if(['account_manager'].includes(this.role)) {
      this.topmenu = [
        { i: ClinicSvg, to: '/superadmin/clinics', title: 'clinics' },
        { i: DollarSvg, to: '/superadmin/payments/list', title: 'payments' },
      ]
    }
    if(this.role === 'superadmin') {
      this.topmenu.push({ i: UserSvg, to: '/superadmin/users', title: 'users' })
      this.topmenu.push({ i: ShieldSvg, to: '/superadmin/dictionaries/countries', title: 'Справочники' })
      this.topmenu.push({ i: LogSvg, to: '/superadmin/login_logs', title: 'Логи авторизации' })
      this.topmenu.push({ i: SadSvg, to: '/superadmin/developer', title: 'Уголок разраба' })
    }
  },
}
</script>

<style scoped lang="scss">
.sidebar__svg-report {
  margin-left: 1px;
}
</style>
