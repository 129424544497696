<template>
  <b-modal id="tech-support-log-modal"
           title="Авторизация в клинику"
           @show="requestAccess"
           @hide="hideAccessRequests"
           size="md"
           centered>
    <div v-if="approved === null">
      <div class="text-center mb-3">
        <b-spinner></b-spinner>
      </div>
      Запрашиваем разрешение у суперадминистраторов клиники, не закрывайте это окно.
    </div>
    <div v-if="approved === true">
      <div class="text-center mb-3">
        <b-spinner></b-spinner>
      </div>
      <div class="alert alert-info">
        Доступ подтвержден - авторизуем
      </div>
    </div>
    <div v-if="approved === false" class="alert alert-danger">Клиника отказала в доступе</div>
    <template #modal-footer="{ close }">
      <div class="modal-footer-buttons">
        <b-button class="cancel d-inline-block align-middle"
                  variant="themed"
                  @click="close()">
          {{ $t('close') }}
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script setup>
import { SuperadminService } from "@/services/api.service"
import { computed, onBeforeUnmount, ref } from "vue"
import { useStore } from "vuex"
import initEcho from "@/extensions/plugins/echo"
import JwtService from "@/services/jwt.service"

const store = useStore()

const approved = ref(null)
const clinicId = computed(() => store.state.auth.clinic.id)
const userId = computed(() => store.state.auth.user.id)
const userIdToImitateLogin = computed(() => store.state.superadmin.userIdToImitateLogin)

let echo = null

const requestAccess = async () => {
  approved.value = null
  startEcho()
  const res = await SuperadminService.requestClinicAuthAccess({
    clinicId: clinicId.value,
    userIdToImitateLogin: userIdToImitateLogin.value
  })
  console.log(res)
}

onBeforeUnmount(() => {
  if(echo) {
    echo.disconnect()
    echo = null
  }
})

const startEcho = () => {
  if(!echo) {
    echo = initEcho(false, true)
    echo.options.auth.headers.Authorization = 'Bearer '+JwtService.getToken()
    echo.private(`superuser.${userId.value}`)
        .listen('.superuserMessageSent', (data) => {
          console.log('superuserMessageSent', data)
          processMessage(data)
        }).error(err => console.log(err))

  }
}

const hideAccessRequests = () => {
  SuperadminService.hideAccessRequests({
    clinicId: clinicId.value,
    userIdToImitateLogin: userIdToImitateLogin.value
  })
}

const processMessage = async (data) => {
  if(data.message.type === 'accessRequestResponse') {
    approved.value = data.message.data.approved
    if(approved.value && data.message.data.token) {
      JwtService.destroyToken()
      JwtService.saveToken(data.message.data.token, false)
      window.location.href = window.location.origin
    }

  }
}
</script>
