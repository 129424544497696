export default {
    additional: "Qo'shimcha",
    alternative: "Muqobil",
    amount_completed: "Bajarilgan miqdor",
    apply_plan_treatment: "Davolash rejasini qo'llash",
    are_you_sure_you_want_to_cancel_plan: "Rejani bekor qilmoqchimisiz?",
    calls_planning: "Qo‘ng‘iroqlarni rejalashtirish",
    choose_criterias_for_fill: "To'ldirish uchun kerakli mezonlarni tanlang",
    comments: "Izohlar",
    completed_sum: "Bajarilgan summa",
    grouped: "Guruhlangan",
    grouped_by_teeth: "Tishlar bo'yicha guruhlangan",
    grouped_by_services: "Xizmatlar bo'yicha guruhlangan",
    last_update: "Oxirgi tahrirlangan",
    level_description: "Bosqich tavsifi",
    main_complaints: "Asosiy shikoyatlar",
    not_signed: "Imzalanmagan",
    notification_date: "Bildirishnoma sanasi",
    plan: "Reja",
    plan_treatment_status: "Davolash rejasi holati",
    plan_type: "Reja turi",
    planned_sum: "Rejalanmagan summa",
    recommended: "Tavsiya etilgan",
    reset_all: "Hammasini tiklang",
    route_list: "Yo'nalish varaqasi",
    select_all: "Hammasini tanlang",
    sign_without_upload: "Yuklab olmasdan ro'yxatdan o'ting",
    signed_at: "Imzolangan",
    total_sum: "Jami summa",
    treatment_plan_amount: "Davolash rejasining miqdori",
}
