<template>
  <a @click="editPlanTreatment" class="tdclickable">
    {{ planTreatment.message }}
  </a>
</template>

<script setup>
import {useStore} from "vuex"
import {computed, getCurrentInstance } from "vue"
import {copyObject} from "@/extensions/prototypes/prototypes"

const props = defineProps({
  object: {
    type: Object
  },
})

const store = useStore()
const instance = (getCurrentInstance())

const planTreatment = computed(() => props.object.plan_treatment)

const  editPlanTreatment = () => {
  store.commit('setVisit', copyObject(planTreatment.value))
  store.commit('setPatient', copyObject(planTreatment.value.patient))
  const bvModal = instance?.ctx?._bv__modal
  bvModal?.show('plan-treatment-modal')
}
</script>
