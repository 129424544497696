export default {
    tag_ACCRUED_BONUSES: "Hisoblangan bonuslar",
    tag_ADDRESS: "Bemor manzili",
    tag_ADDRESS_PREDSTAVITEL: "Mijoz vakilining manzili",
    tag_BIRTHDAY: "Bemorning tug'ilgan sanasi",
    tag_BIRTHDAY_PREDSTAVITEL: "Mijoz vakilining tug'ilgan sanasi",
    tag_BRANCH_ADDRESS: "Filial manzili",
    tag_BRANCH_BIN: "Filial BIN",
    tag_BRANCH_INN: "Filial INN",
    tag_BRANCH_KPP: "Filial KPP",
    tag_BRANCH_LICENSE: "Filial litsenziyasi",
    tag_BRANCH_OGRN: "Filial OGRN",
    tag_BRANCH_TITLE: "Filial nomi",
    tag_CARD_CLIENT: "Mijoz karta raqami",
    tag_CLIENT: "Mijoz F.I.O.",
    tag_CLIENT_BONUSES: "Mijoz bonuslari",
    tag_CLIENT_NAME: "Mijoz nomi",
    tag_CLIENT_NAME_MNAME: "Mijoz nomi otasining ismi",
    tag_CLINIC: "Klinika nomlanishi",
    tag_COMPLEX_STATUS: "Davolash rejasi paketining holati",
    tag_COST_DISCOUNTED: "Xizmatlar narxi, shu jumladan chegirma",
    tag_COST_ORIGINAL: "Chegirmasiz xizmatlar narxi",
    tag_DATA: "Hujjatni yaratish sanasi",
    tag_DATA_VIZIT: "Tashrif sanasi",
    tag_DATE: "Tarqatma xatlarni yaratish sanasi",
    tag_DATE_FIRST_VIZIT: "Birinchi tashrif sanasi",
    tag_DIAGNOZ: "Tashxis",
    tag_DIAGNOZ_PLAN: "Davolash rejasidagi tashxis",
    tag_DOCTOR: "FIO – shifokor FIO",
    tag_DOCUMENT_TYPE_PREDSTAVITEL: "Mijoz vakilining hujjat turi",
    tag_FIO_CLIENT: "Mijoz FIO",
    tag_FIO_DIRECTOR: "Direktor FIO",
    tag_IIN: "Bemorning IIN",
    tag_INN: "Bemorning INN",
    tag_LEGAL_ADDRESS: "Klinikaning yuridik manzili",
    tag_LEGAL_COMPANY_NAME: "Klinikaning yuridik nomlanishi",
    tag_PASSPORT: "Bemorning pasport raqami",
    tag_PASSPORT_CODE: "Bemorning pasport bo'limi kodi",
    tag_PASSPORT_CODE_PREDSTAVITEL: "Mijoz vakilining pasport bo'limi kodi",
    tag_PASSPORT_DATE: "Bemorning pasport berilgan sana",
    tag_PASSPORT_DATE_PREDSTAVITEL: "Mijoz vakilining hujjati berilgan sana",
    tag_PASSPORT_ISSUED: "Bemorning pasport kim tomonidan berilgan",
    tag_PASSPORT_ISSUED_PREDSTAVITEL: "Mijoz vakilining hujjati kim tomonidan berilgan",
    tag_PASSPORT_PREDSTAVITEL: "Mijoz vakilining pasport raqami",
    tag_PASSPORT_SERIES: "Bemorning pasport seriyasi",
    tag_PASSPORT_SERIES_PREDSTAVITEL: "Mijoz vakilining hujjati seriyasi",
    tag_PHONE: "Mijoz telefoni",
    tag_PHONE_CLIENT: "Mijoz telefoni",
    tag_PHONE_PREDSTAVITEL: "Mijoz vakilining telefon raqami",
    tag_PLAN_STATUS: "Davolash rejasi holati",
    tag_PLAN_TITLE: "Davolash rejasining nomi",
    tag_PREDSTAVITEL_PACIENTA: "Mijoz vakili FIO",
    tag_SNILS: "Bemorning SNILS",
    tag_SNILS_PREDSTAVITEL: "Mijoz vakili SNILS raqami",
    tag_TASK: "Topshiriq matni",
    tag_URL_VIZIT_CONFIRMATION: "Tashrifingizni tasdiqlash uchun havola",
    tag_URL_VIZIT_REVIEW: "Tashrifni ko'rib chiqish uchun havola",
    tag_USLUGI: "Xizmatlar",
    tag_USLUGI_PLAN: "Davolash rejasidagi xizmatlar",
    tag_VISIT_DOCTOR: "Tashrifda bo‘lgan shifokor FIOsi",
}
