export default {
    account_manager: "Аккаунт менеджер",
    administrator: "Администратор",
    administrators: "Администраторы",
    are_you_sure_you_want_to_delete_field: "Вы уверены, что хотите удалить направление",
    assistants: "Ассистенты",
    block: "Заблокировать",
    by_days: "По дням",
    by_days_of_week: "По дням недели",
    by_even_days: "По четным дням",
    by_odd_days: "По нечетным дням",
    chair: "Кресло",
    chairs: "Кресла",
    clinic_access_request: "Запрос доступа к клинике",
    config: "Конфигурация",
    confirm_password: "Повторить пароль",
    curator: "Куратор",
    curators: "Кураторы",
    dentist_plus_tech_support_requests_access_to_your_clinic: "Техническая поддержка Dentist+ запрашивает доступ к вашей клинике",
    doctor_position: "Должность врача",
    doctor_specialty: "Специальность врача",
    field: "Направление",
    fields: "Направления",
    functionality: "Функционал",
    login_and_password: "Логин и пароль",
    manual_pay_system: "Ручная платежная система",
    not_worked: "Не отработано",
    partner: "Партнер",
    password_does_not_match_confirmation: "Пароль не совпадает с подтверждением",
    percent: "Процент",
    period_from: "Период с",
    period_to: "Период до",
    phone2: "Телефон 2",
    profession: "Специальность",
    rate: "Ставка",
    rate_per_day: "Ставка в день",
    rate_per_hour: "Ставка в час",
    rate_per_month: "Ставка в месяц",
    roles: "Роли",
    schedule_conflicts_found: "Найдено конфликтов расписания с текущими записями",
    staff: "Персонал",
    technics: "Техники",
    weekend: "Выходной",
    without_warehouse_form_note: "Без привязки к складу, материалы будут списываться с основного склада или со склада, привязанного к креслу",
    work_day: "Рабочий день",
    work_days: "Рабочие дни",
    work_schedule: "График работы",
    work_time: "Рабочее время",
    worked: "Отработано",
    user_data: "Данные пользователя",
    user_will_assigned_rights_selected_for_that_role: "Пользователю будут назначены права, выбранные для этой роли",
}
