export const notificationSettingAdapter =
    ({
       id,
       title,
       notification_type,
       recipient_role_id,
       text,
       notification_channels,
       timeType,
       minutes,
       send_only_during_day,
       confirm_response,
       cancel_response,
       active,
       services,
     }) => ({
      id: id ? id : null,
      title: title ? title : null,
      notification_type: notification_type ? notification_type : null,
      recipient_role_id: recipient_role_id ? recipient_role_id : null,
      text: text ? text : null,
      notification_channels: notification_channels ? notification_channels : [],
      minutes: countMinutes(timeType, minutes),
      send_only_during_day: ['visit_cancel', 'visit_created', 'visit_time_changed'].includes(notification_type) ? (send_only_during_day ? 1 : 0) : 0,
      confirm_response: confirm_response,
      cancel_response: cancel_response,
      active: active ? 1 : 0,
      services: services?.map(service => service.id) || []
    })


function countMinutes(timeType = 'minutes', units = null) {
  if(!units) return null
  units = parseInt(units)
  if(timeType === 'hours') return units * 60
  if(timeType === 'days') return units * 60 * 24
  return units
}

