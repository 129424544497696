import authorized from "@/middleware/authorized"
import paid from "@/middleware/paid"

export default {
    path: '/treatment_plans', name: 'TreatmentPlansGeneral',
    meta: { middleware: [authorized, paid], layout: "default", rights: ['planning_list'] },
    component: () => import('../components/pages/treatment_plans/TreatmentPlansGeneral.vue'),
    children: [
        { path: '', redirect: '/treatment_plans/plans' },
        {
            path: 'plans', name: "TreatmentPlans",
            meta: { middleware: [authorized, paid], layout: "default", rights: ['planning_list'] },
            component: () => import('../components/pages/treatment_plans/TreatmentPlans.vue'),
        },
        {
            path: 'complexes', name: "TreatmentPlanComplexes",
            meta: { middleware: [authorized, paid], layout: "default", rights: ['planning_list'] },
            component: () => import('../components/pages/treatment_plans/TreatmentPlanComplexes.vue'),
        },
    ]
}
