export default {
    accept_payment: "Ödənişi qəbul et",
    added: "Əlavə etdi",
    add_new_record: "+ Yeni vizit əlavə et",
    additionally: "Əlavələr",
    are_you_sure_want_to_move_visit: "Siz həqiqətən viziti köçürmək istəyirsiniz?",
    are_you_sure_want_to_change_time: "Siz həqiqətən vizitin vaxtını dəyişmək istəyirsiniz?",
    are_you_sure_you_want_to_delete_record: "Siz həqiqətən qeydi silmək istəyirsiniz?",
    blocked: "Bloklanıb",
    by_phone_found: "Telefon nömrəsi {phone} ilə aşağıdakı xəstələr tapıldı",
    by_phone_nobody_found: "Telefon nömrəsi {phone} ilə heç bir xəstə tapılmadı",
    calendar_settings: "Təqvim ayarları",
    cancel_record: "Qeydi ləğv et",
    change_record: "Qeydi dəyiş",
    checkup: "Müayinə",
    child_age: "18 yaşdan kiçik",
    choose_all: "Hamısını seç",
    clear: "Təmizlə",
    create_notification: "Bildiriş yarat",
    create_patient: "Xəstə yarat",
    create_task: "Tapşırıq yarat",
    confirm_visit: "Viziti təsdiqlə",
    curation: "Kurasiya",
    DAY: "Gün üzrə",
    day_short: "gün",
    days: "günlər",
    delete_record: "Viziti sil",
    diagnostics: "Diaqnostika",
    doctor_available_time: "Həkimin mövcud vaxtı",
    doctor_has_no_available_time_at_this_day: "Bu gün həkimin mövcud vaxtı yoxdur",
    doctor_is_not_working_on_this_date_continue: "Həkim bu vaxtda işləmir və ya bu vaxt doludur. Davam edək?",
    doctors_selected: "Seçilmiş həkimlər",
    edit_schedule: "Cədvəli redaktə et",
    edit_visit: "Viziti redaktə et",
    email_text: "E-poçt mətnini yaz",
    email_topic: "E-poçt mövzusu",
    employees_selected: "Seçilmiş əməkdaşlar",
    fillDoctorColor: "Həkimin rəngi ilə qeydiyyatı rəngləmək",
    fill_treatment: "Müalicəni doldur",
    for: "Üçün",
    free_minutes: "Boş dəqiqələr",
    hideChairsWithoutSchedule: "Qrafiki olmayan kresloları gizlət",
    hideDoctorsWithoutSchedule: "Seçilmiş gün üçün qrafiki olmayan həkimləri gizlət",
    hour_short: "saat",
    info: "Məlumat",
    load_demo_data_to_account: "Demo məlumatları hesaba yükləmək?",
    min_short: "dəq",
    min_shortest: "d",
    move_record: "Qeydi köçür",
    need_to_select_at_least_one_doctor: "Ən azı bir həkim seçmək lazımdır",
    need_to_select_at_least_one_employee: "Ən azı bir əməkdaşı seçmək lazımdır",
    new_visit: "Yeni vizit",
    no_birthdays_on_this_date: "Bu tarixdə doğum günü yoxdur",
    no_free_time: "Boş vaxt yoxdur",
    no_schedule: "Cədvəl yoxdur",
    no_visits: "Vizit yoxdur",
    not_working: "İşləmir",
    of_day: "günün",
    online_consultation: "Onlayn konsultasiya",
    patient_came: "Xəstə gəldi",
    patient_card: "Xəstə kartı",
    patient_notifications: "Xəstəyə bildirişlər",
    postponed: "Təxirə salınıb",
    quick_view: "Sürətli baxış",
    record_again: "Yenidən qeyd et",
    record_conflict: "Qeyd münaqişəsi",
    record_has_no_paid_services: "Qeyddə ödənişli xidmət yoxdur",
    record_type: "Qeydin növü",
    related_tasks: "Əlaqəli tapşırıqlar",
    requires_confirmation: "Təsdiq tələb edir",
    return_visit: "Təkrar vizit",
    sanation: "Sanasiya",
    schedule: "Cədvəl",
    select_a_doctor: "Həkimi seçin",
    select_an_employee: "Əməkdaşı seçin",
    selected: "Seçildi",
    send_sms: "SMS göndər",
    sms_text: "SMS mətni",
    showClientTags: "Xəstələrin qeyd rənglərini istifadə et",
    showDoctorColor: "Həkim rənglərini istifadə et",
    showPlannedServices: "Xidmətləri mətnlə göstər",
    showRecordTagColor: "Vizitin fonu kimi etiketi rənglə",
    showServiceColor: "Xidmət kateqoriyalarının rənglərini istifadə et",
    showStatusColor: "Vizit statuslarının rənglərini istifadə et",
    showOverflowScreen: "Böyük məlumat dəsti üçün ekranı sürüşdürməyi istifadə et",
    till: "qədər",
    time_has_already_passed: "Vaxt keçib",
    tomorrow: "Sabah",
    visits_count: "Vizit yoxdur | vizit | vizitlər",
    visits_count_much: "vizitlər",
    warranty_case: "Zəmanət işi",
    write_reason_for_cancellation: "Ləğv səbəbini yazın",
    yesterday: "Dünən",
    you_have_no_doctors: "Sizdə həkim yoxdur. Həkim əlavə etmək üçün <a href='{href}'>buraya</a> keçin",
    WEEK: "Həftə üzrə",
    MONTH: "Ay üzrə",
    CHAIR: "Kreslolar üzrə",
    DOCTOR: "Həkimlər üzrə",
}
