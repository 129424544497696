import Echo from 'laravel-echo'
window.io = require('socket.io-client')

export default function initEcho(useProxy = true, superadmin = false) {
    const host = useProxy ?
        'https://proxyapi.dentist-plus.com:6002'
        : process.env.VUE_APP_API_DOMAIN + ':6001'
    const options = {
        broadcaster: "socket.io",
        host,
        auth: {
            headers: {
                Authorization: 'Bearer '
            }
        }
    }
    if(superadmin) {
        options.auth.headers.superadmin = 'superadmin'
    }
    return new Echo(options)
}
