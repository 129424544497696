<template>
  <span class="inline-action-buttons">
    <img class="img" v-if="img" :src="img"/>
    <a :title="$t('edit')"
       @click="editPlanTreatment"
       v-b-tooltip.hover
       class="data-table-action">
    <EditSvg/><span class="action-button-mob-title">{{ $t('edit') }}</span>
  </a>
    <a :title="$t('refresh_prices')"
       v-if="!isComplexesView"
       @click="updatePlanTreatmentPrices"
       v-b-tooltip.hover
       class="data-table-action">
    <RefreshSvg/><span class="action-button-mob-title">{{ $t('refresh_prices') }}</span>
  </a>
    <a :title="$t('sign')"
       v-if="!planTreatment.signed_at"
       @click="sign"
       v-b-tooltip.hover
       class="data-table-action">
    <SignSvg class="svg-light svg-size-xl"/><span class="action-button-mob-title">{{ $t('sign') }}</span>
  </a>
    <a class="data-table-action"
       v-if="!isComplexesView"
       v-b-tooltip.hover
       :title="$t('print')">
      <b-dropdown right variant="link" no-caret>
        <template #button-content>
          <PrintSvg/><span class="action-button-mob-title">{{ $t('print') }}</span>
        </template>
        <a class="dropdown-item pointer" @click="printPlan">
          {{ $t('standard') }}
        </a>
        <a class="dropdown-item pointer" @click="printPlanGrouped">
          {{ $t('grouped') }}
        </a>
        <a class="dropdown-item pointer" @click="printPlanGroupedByTeeth">
          {{ $t('grouped_by_teeth') }}
        </a>
        <a class="dropdown-item pointer" @click="printPlanGroupedByServices">
          {{ $t('grouped_by_services') }}
        </a>
        <a class="dropdown-item pointer" @click="printPlanFact">
          {{ $t('plan_fact') }}
        </a>
        <a class="dropdown-item pointer" @click="printWithFormula">
          {{ $t('with_formula') }}
        </a>
         <a class="dropdown-item pointer" @click="printRouteList">
          {{ $t('route_list') }}
        </a>
        <div class="dropdown-divider"></div>
        <div class="dropdown-header font-weight-bold">{{  $t('by_complexes') }}</div>
        <a class="dropdown-item pointer"
           v-for="(complex, index) in object.visits"
           :key="`visit-${complex.id}`"
           @click="printPlanComplex(complex)">
          {{ complex.title ? complex.title : $t('complex')+' '+(index+1) }}
        </a>
      </b-dropdown>
    </a>
    <a v-if="isComplexesView"
       class="data-table-action"
       v-b-tooltip.hover
       @click="printPlanComplex(object)"
       :title="$t('print')">
      <PrintSvg/><span class="action-button-mob-title">{{ $t('print') }}</span>
    </a>
    <PlanSignModal v-if="showModal"
                   :plan="planTreatment"
                   @hidden="showModal = false"/>
  </span>
</template>

<script>

import EditSvg from '@/assets/svg-vue/general/edit.svg'
import PrintSvg from '@/assets/svg-vue/general/print2.svg'
import RefreshSvg from '@/assets/svg-vue/general/refresh2.svg'
import JwtService from "@/services/jwt.service"
import {mapActions, mapState} from "vuex"
import {PatientService, CalendarService} from "@/services/api.service"
import SignSvg from "@/assets/svg-vue/patients/sign.svg.vue"
import PlanSignModal from "@/components/parts/planning/PlanSignModal.vue"
import {copyObject} from "@/extensions/prototypes/prototypes"

export default {
  name: "PatientDatatablePlanTreatmentActions",
  components: {
    PlanSignModal,
    SignSvg,
    EditSvg,
    PrintSvg,
    RefreshSvg
  },
  props: {
    object: {
      type: Object
    }
  },
  computed: {
    ...mapState({
      clinic: state => state.auth.clinic,
    }),
    isComplexesView() {
      return this.object.model === 'plan_visit'
    },
    planTreatment() {
      return this.isComplexesView ? this.object.plan_treatment : this.object
    },
    planVisit() {
      return this.isComplexesView ? this.object : null
    }
  },
  data() {
    return {
      img: null,
      showModal: false
    }
  },
  methods: {
    ...mapActions(['getPatientTeeth']),
    editPlanTreatment() {
      this.$store.commit('setPatient', copyObject(this.planTreatment.patient))
      this.$store.commit('setVisit', copyObject(this.planTreatment))
      this.$store.commit('setPlanVisit', this.isComplexesView ? copyObject(this.object) : null)
      this.$bvModal.show('plan-treatment-modal')
    },
    copyPlanTreatment() {
      CalendarService.duplicatePlanTreatment(this.planTreatment.planning_id).then(() => {
        this.$noty.info(this.$t('success_message'))
        this.$store.commit('incrementDataTableCounter')
      }).catch((e) => { console.log(e) })
    },
    updatePlanTreatmentPrices() {
      CalendarService.updatePlanTreatmentPrices(this.planTreatment.planning_id).then(() => {
        this.$noty.info(this.$t('success_message'))
      }).catch((e) => { console.log(e) })
    },
    printPlan() {
      const link = JwtService.getPrintPrelink(this.clinic)
      window.open(link+'/plan/'+this.planTreatment.planning_id, '_blank')
    },
    printPlanComplex(complex) {
      const link = JwtService.getPrintPrelink(this.clinic)
      window.open(link+'/plan/'+this.planTreatment.planning_id+'?complexId='+complex.id, '_blank')
    },
    printPlanGrouped() {
      const link = JwtService.getPrintPrelink(this.clinic)
      window.open(link+'/plan/'+this.planTreatment.planning_id+'?grouped=1', '_blank')
    },
    printPlanGroupedByTeeth() {
      const link = JwtService.getPrintPrelink(this.clinic)
      window.open(link+'/plan/'+this.planTreatment.planning_id+'?grouped=1&by_teeth=1', '_blank')
    },
    printPlanGroupedByServices() {
      const link = JwtService.getPrintPrelink(this.clinic)
      window.open(link+'/plan/'+this.planTreatment.planning_id+'?grouped=1&by_services=1', '_blank')
    },
    printPlanFact() {
      const link = JwtService.getPrintPrelink(this.clinic)
      window.open(link+'/plan/'+this.planTreatment.planning_id+'?planfact=1', '_blank')
    },
    async printWithFormula() {
      this.$store.commit('setPatient', copyObject(this.planTreatment.patient))
      await this.getPatientTeeth(this.planTreatment.patient.id)
      const el = document.getElementById('patientFormulaSelectedView')
      const options = {
        type: 'dataURL',
        allowTaint: true,
        onclone: function(doc) {
          const hiddenDiv = doc.getElementById('patientFormula')
          hiddenDiv.style.display = 'block'
        }
      }
      const style = document.createElement('style') // this style addition is a hack to keep the styling, otherwise some paddings appear and image is cropped
      document.head.appendChild(style)
      style.sheet?.insertRule('body > div:last-child img { display: inline-block; }')
      await this.$nextTick()
      try {
        const output = await this.$html2canvas(el, options)
        style.remove()
        const res = await PatientService.sendFormulaImage(this.planTreatment.client_id, output)
        if (res.data) {
          const link = JwtService.getPrintPrelink(this.clinic)
          window.open(link + '/plan/' + this.planTreatment.planning_id + '?formula=1', '_blank')
        }
      } catch (e) {
        console.log(e)
      }
    },
    printRouteList() {
      const link = JwtService.getPrintPrelink(this.clinic)
      window.open(link+'/plan/'+this.planTreatment.planning_id+'?route_list=1', '_blank')
    },
    async sign() {
      this.showModal = true
      await this.$nextTick()
      this.$bvModal.show('plan-sign-modal')
    },
  }
}
</script>

<style scoped lang="scss">
.inline-action-buttons {
  display: contents;
}
</style>
