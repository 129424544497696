export default {
    adjustment: "Adjustment",
    add_all_materials_from_warehouse: "Add all materials from warehouse",
    add_material: "Add material",
    add_new: "Add new",
    adding_new_supplier: "Adding new supplier",
    address: "Address",
    addition: "Arrival",
    amount: "Amount",
    amount_of_stock_on_all_warehouses: "The total quantity of goods available in all warehouses of the clinic",
    approve: "Approve",
    approved: "Approved",
    are_you_sure_you_want_to_delete_group: "Are you sure you want to delete the group?",
    are_you_sure_you_want_to_delete_kit: "Are you sure you want to delete the kit?",
    are_you_sure_you_want_to_delete_supplier: "Are you sure you want to delete the supplier?",
    are_you_sure_you_want_to_delete_warehouse: "Are you sure you want to delete the warehouse?",
    artikul: "Article",
    bank_requisites: "Bank details",
    barcode: "Barcode",
    beginning_balance: "Beginning balance",
    bin: "BIN",
    branch: "Branch",
    bulk_addition: "Add by group",
    by_categories: "By categories",
    consumption: "Expense",
    consumption_manual: "Manual expense",
    contact_person: "Contact person",
    cost: "Price",
    created: "Created",
    current_stock: "Current balance",
    destination: "Destination",
    details: "Details",
    difference: "Difference",
    documents: "Documents",
    draft: "Draft",
    enter_title_or_barcode: "Enter title or barcode",
    factual_amount: "Factual amount",
    finished: "Finished",
    from: "From",
    from_warehouse: "Movement from warehouse",
    from_where: "From where",
    general_price: "Total amount",
    general_stock: "Total balance",
    group: "Group",
    groups: "Groups",
    id: "Id",
    inventarization: "Inventory",
    Kit: "Kit",
    kit: "Kit",
    kits: "Kits",
    material: "Material",
    materialgroup: "Group",
    materialstock: "Material",
    materials: "Materials",
    movement: "Movement",
    movements: "Movements",
    movement_from: "Movement",
    movement_to: "Movement",
    nomenclature: "Range of items",
    notify_when_stock: "Notify on balance",
    operation: "Operation",
    parent_category: "Parent category",
    parent_group: "Parent group",
    physical: "Physical",
    print: "Print",
    prohibit_minus_stocks: "Prohibit writing off materials into negative balance",
    return: "Return",
    returns: "Returns",
    stocks: "Leftovers",
    stocks_archive: "Materials' archive",
    supplier: "Supplier",
    suppliers: "Suppliers",
    to_warehouse: "Movement to warehouse",
    to_where: "Where",
    type: "Type",
    unit_of_measure: "Units",
    virtual: "Virtual",
    warehouse_amount: "Warehouse amount",
    warehouses: "Warehouses",
    warehouseoperation: "Operations",
    write_off: "Write off"
}
