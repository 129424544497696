import authorized from "@/middleware/authorized"
import paid from '@/middleware/paid'

import PatientGeneral from "@/components/pages/patients/PatientGeneral"
import PatientVisits from "@/components/pages/patients/PatientVisits"
import PatientVisit from "@/components/pages/patients/PatientVisit"
import PatientFormula from "@/components/pages/patients/PatientFormula"
import PatientPayments from "@/components/pages/patients/PatientPayments"
import PatientDepositTransactions from "@/components/pages/patients/PatientDepositTransactions"
import PatientFamilyAccount from "@/components/pages/patients/PatientFamilyAccount"
import PatientTreatmentPlan from "@/components/pages/patients/PatientTreatmentPlan"
import PatientInsurancePolises from "@/components/pages/patients/PatientInsurancePolises"
import PatientScans from "@/components/pages/patients/PatientScans"
import PatientNotes from "@/components/pages/patients/PatientNotes"
import PatientDocuments from "@/components/pages/patients/PatientDocuments"
import PatientPersonal from "@/components/pages/patients/PatientPersonal"
import PatientComments from "@/components/pages/patients/PatientComments"
import PatientClientService from "@/components/pages/patients/PatientClientService"
import PatientNotifications from "@/components/pages/patients/PatientNotifications"
import PatientNDFLs from "@/components/pages/patients/PatientNDFLs"

export default {
    path: '/patients/:id',
    meta: { middleware: [authorized, paid], layout: "default", rights: ['patient_card'] },
    // component: () => import('../components/pages/patients/PatientGeneral.vue'),
    component: PatientGeneral,
    children: [
        { path: '', redirect: to => ({ name: 'PatientVisits', params: { id: to.params.id } }) },
        {
            path: 'visits', name: "PatientVisits",
            meta: { middleware: [authorized, paid], layout: "default", rights: ['patient_card'] },
            // component: () => import('../components/pages/patients/PatientVisits.vue')
            component: PatientVisits
        },
        {
            path: 'visits/:visitid/:page', name: "PatientVisitPage",
            meta: { middleware: [authorized, paid], layout: "default", rights: ['patient_card'] },
            // component: () => import('../components/pages/patients/PatientVisit.vue'),
            component: PatientVisit,
        },
        {
            path: 'formula', name: "PatientFormula",
            meta: { middleware: [authorized, paid], layout: "default", rights: ['patient_card'] },
            // component: () => import('../components/pages/patients/PatientFormula.vue')
            component: PatientFormula
        },
        {
            path: 'payments', name: "PatientPayments",
            meta: { middleware: [authorized, paid], layout: "default", rights: ['patient_card'] },
            // component: () => import('../components/pages/patients/PatientPayments.vue')
            component: PatientPayments
        },
        {
            path: 'deposit', name: "PatientDepositTransactions",
            meta: { middleware: [authorized, paid], layout: "default", rights: ['patient_card', 'deposit'] },
            // component: () => import('../components/pages/patients/PatientDepositTransactions.vue')
            component: PatientDepositTransactions
        },
        {
            path: 'family_account', name: "PatientFamilyAccount",
            meta: { middleware: [authorized, paid], layout: "default", rights: ['patient_card', 'family_account'] },
            // component: () => import('../components/pages/patients/PatientFamilyAccount.vue')
            component: PatientFamilyAccount
        },
        {
            path: 'treatment_plan', name: "PatientTreatmentPlan",
            meta: { middleware: [authorized, paid], layout: "default", rights: ['patient_card', 'planning'] },
            // component: () => import('../components/pages/patients/PatientTreatmentPlan.vue')
            component: PatientTreatmentPlan
        },
        {
            path: 'insurance', name: "PatientInsurancePolises",
            meta: { middleware: [authorized, paid], layout: "default", rights: ['patient_card'] },
            // component: () => import('../components/pages/patients/PatientTreatmentPlan.vue')
            component: PatientInsurancePolises
        },
        {
            path: 'scans', name: "PatientScans",
            meta: { middleware: [authorized, paid], layout: "default", rights: ['scans','patient_card'] },
            // component: () => import('../components/pages/patients/PatientScans.vue')
            component: PatientScans
        },
        {
            path: 'notes', name: "PatientNotes",
            meta: { middleware: [authorized, paid], layout: "default", rights: ['patient_card'] },
            // component: () => import('../components/pages/patients/PatientNotes.vue')
            component: PatientNotes
        },
        {
            path: 'documents', name: "PatientDocuments",
            meta: { middleware: [authorized, paid], layout: "default", rights: ['patient_card', 'documents_upload'] },
            // component: () => import('../components/pages/patients/PatientDocuments.vue')
            component: PatientDocuments
        },
        {
            path: 'personal', name: "PatientPersonal",
            meta: { middleware: [authorized, paid], layout: "default", rights: ['patient_card'] },
            // component: () => import('../components/pages/patients/PatientPersonal.vue')
            component: PatientPersonal
        },
        {
            path: 'comments', name: "PatientComments",
            meta: { middleware: [authorized, paid], layout: "default", rights: ['patient_card'] },
            // component: () => import('../components/pages/patients/PatientComments.vue')
            component: PatientComments
        },
        {
            path: 'service', name: "PatientClientService",
            meta: { middleware: [authorized, paid], layout: "default", rights: ['patient_card'] },
            component: PatientClientService
        },
        {
            path: 'notifications', name: "PatientNotifications",
            meta: { middleware: [authorized, paid], layout: "default", rights: ['patient_card'] },
            component: PatientNotifications
        },
        {
            path: 'ndfl', name: "PatientNDFLs",
            meta: { middleware: [authorized, paid], layout: "default" },
            component: PatientNDFLs
        },
    ]
}
