<template>
  <div>
<!--    <div class="row">-->
<!--      <div class="col-md-6">-->
<!--        <DateGroup :label="$t('date_from')"-->
<!--                   :required="true"-->
<!--                   v-model="object.data['from']"/>-->
<!--      </div>-->
<!--      <div class="col-md-6">-->
<!--        <DateGroup :label="$t('date_to')"-->
<!--                   :required="true"-->
<!--                   v-model="object.data['to']"/>-->
<!--      </div>-->
<!--    </div>-->
    <div class="row align-items-end">
      <div v-for="(value, id) in dataToBeEdited" :key="id"
           class="col-md-4">
        <DateGroup v-if="['taxpayer_date_of_birth', 'taxpayer_passport_issue_date', 'patient_date_of_birth', 'patient_passport_issue_date'].includes(id)"
                   :label="dictionary[id]"
                   v-model="object.data[id]"/>
        <InputGroup v-else :label="dictionary[id]" v-model="object.data[id]"/>
      </div>
    </div>
    <div class="mb-4">
      <CheckboxGroup fid="myDeals"
                     label="Есть представитель"
                     class="mb-0"
                     v-model="object.data['hasRepresentative']"
                     placeholder="Есть представитель"/>
    </div>
    <div class="mt-4">
      <div class="row no-gutters justify-content-end">
        <div class="col-auto">
          <button class="btn-themed btn-themed-squared btn-themed-outline"
                  @click="printDocPDF">
            {{ $t('print') }}
          </button>
        </div>
        <div class="col-auto ml-2">
          <button class="btn-themed btn-themed-squared btn-themed-outline"
                  @click="printDocXML">
            XML
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {computed, onMounted} from "vue"

import InputGroup from "@/components/form/InputGroup.vue"
import { appendFormdata, copyObject } from "@/extensions/prototypes/prototypes"
import JwtService from "@/services/jwt.service"
import { useStore } from "vuex"
import { EloquentService } from "@/services/api.service"
import { reportPopulatedAdapter } from "@/adapters/patients/reportPopulated"
import DateGroup from "@/components/form/DateGroup.vue"
import CheckboxGroup from "@/components/form/CheckboxGroup.vue"

const props = defineProps({
  object: {
    type: Object,
  },
  field: {
    type: Object,
  }
})

const store = useStore()

const dataToBeEdited = computed(() => {
  const obj = copyObject(props.object.data)
  delete obj.sums
  delete obj.dates
  delete obj.hasRepresentative
  delete obj.from
  delete obj.to
  return obj
})

const clinic = computed(() => store.state.auth.clinic)
const branchId = computed(() => store.state.auth.branch)

const dictionary = {
  'ndflCounter': 'Номер справки',
  'to': 'Дата от',
  'from': 'Дата до',
  'correctionNum': 'Номер корректировки',
  'docDate': 'Дата документа',
  'reportYear': 'Отчетный год',
  'signer': 'ФИО подписывающего',
  'hasRepresentative': 'Есть ли представитель',
  'taxpayer_lname': 'Фамилия налогоплательщика',
  'taxpayer_mname': 'Отчество налогоплательщика',
  'taxpayer_fname': 'Имя налогоплательщика',
  'taxpayer_date_of_birth': 'Дата рождения налогоплательщика',
  'taxpayer_taxcode': 'ИНН налогоплательщика',
  'taxpayer_passport_issue_date': 'Дата выдачи документа налогоплательщика',
  'taxpayer_passport_series': 'Серия документа налогоплательщика',
  'taxpayer_passport': 'Номер документа налогоплательщика',
  'taxpayer_document_type': 'Код вида документа налогоплательщика',
  'patient_lname': 'Фамилия пациента',
  'patient_mname': 'Отчество пациента',
  'patient_fname': 'Имя пациента',
  'patient_date_of_birth': 'Дата рождения пациента',
  'patient_taxcode': 'ИНН пациента',
  'patient_passport_issue_date': 'Дата выдачи документа пациента',
  'patient_passport_series': 'Серия документа пациента',
  'patient_passport': 'Номер документа пациента',
  'patient_document_type': 'Код вида документа пациента'
}

onMounted(() => {
  if(props.object.data['hasRepresentative'] === 'false') props.object.data['hasRepresentative'] = false
})

const printDocPDF = () => {
  printDoc('ndfl')
}

const printDocXML = () => {
  printDoc('ndfl_statement_xml')
}

const printDoc = async (documentType) => {
  let formData = new FormData()
  formData = appendFormdata(formData, reportPopulatedAdapter(props.object))
  let id = props.object.id
  if(id) {
    await EloquentService.update('report_populated', props.object.id, formData)
  } else {
    const res = await EloquentService.create('report_populated', formData)
    id = res?.data?.id || null
  }
  let link = JwtService.getPrintPrelink(clinic.value)
  let url = link +'/document/'+props.object.patient_id+'/'+documentType+'?t=r&branchId='+branchId.value
  if(id) {
    url += '&reportId=' + id
  }
  window.open(url, '_blank')
}
</script>

<style scoped>
.btn-themed {
  height: 42px;
}
</style>
