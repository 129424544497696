<template>
  <div>
    <DataTable :options="options"
               :params="{ clinic_id: clinic.id }"
               module="superadmin"/>
    <TechSupportLogToClinicModal/>
  </div>
</template>

<script>

import DataTable from "@/components/parts/general/DataTable"
import options from "@/data/superadmin/patient"
import { mapState } from "vuex"
import TechSupportLogToClinicModal from "@/components/parts/superadmin/TechSupportLogToClinicModal.vue"

export default {
  name: "SuperAdminClinicPatients",
  components: {
    TechSupportLogToClinicModal,
    DataTable
  },
  head() {
    return {
      title: this.$t('patients'),
    }
  },
  computed: {
    ...mapState({
      clinic: state => state.auth.clinic
    })
  },
  data() {
    return {
      options: options
    }
  },
  mounted() {
    this.$store.commit('setEloquentOptions', options)
  }
}
</script>
