<template>
  <div v-if="clinicFull"
       class="mb-3">
    <div class="mb-3">
      <div class="row">
        <div class="col-md-6">
          <div>
            <div class="mb-1">Тариф:</div>
            <CheckboxGroup v-model="object.include_payment_for_core_tariff"
                           class="sm mb-0"
                           fid="core"
                           :label="clinicFull.tariffInfo ? clinicFull.tariffInfo.tariff.title : '-'"/>
          </div>
          <div>
            <div class="mb-1">
              <div class="d-flex">
                Доп функционал:
                <b-dropdown right
                            class="ml-2"
                            v-if="availableFunctionality.length"
                            variant="link"
                            toggle-tag="a"
                            no-caret>
                  <template #button-content>
                    <a class="btn btn-sm btn-themed-squared btn-themed-outline"
                       style="height: 22px; line-height: 20px; padding: 0 12px;">+</a>
                  </template>
                  <b-dropdown-item v-for="paidFunc in availableFunctionality"
                                   :key="`paidFunc-${paidFunc.id}`"
                                   @click="addFunctionality(paidFunc.id)">
                    {{ paidFunc.title }}
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </div>
            <div v-if="clinicTariff">

              <div v-for="func in clinicTariff.paid_functionality"
                   :key="`func-${func}`">
                <div class="row align-items-center">
                  <div class="col">
                    <CheckboxGroup v-model="object.paid_functionality"
                                   :checkboxvalue="func.id"
                                   class="sm mb-0"
                                   :fid="`func-${func.id}`"
                                   :label="func.title"/>
                  </div>
                  <div class="col-auto">
                    <a @click="removeFunctionality(func.id)">
                      <TrashSvg class="svg-light pointer"/>
                    </a>
                  </div>
                </div>
              </div>

              <div class="mt-2">
                <div class="d-flex align-items-center">
                  <CheckboxGroup v-model="additionalDoctorsIncluded"
                                 class="sm mb-0"
                                 fid="additionalDoctorsIncluded"
                                 label="Доп врачей"/>
                  <InputGroup v-model="additionalDoctors"
                              class="sm mb-0 ml-2"
                              type="number"
                              :readonly="!additionalDoctorsIncluded"
                              @change="saveAdditionalDoctors"/>
                </div>
              </div>

              <div>
                <div class="d-flex align-items-center">
                  <CheckboxGroup v-model="additionalSpaceIncluded"
                                 class="sm mb-0"
                                 fid="additionalSpaceIncluded"
                                 label="Доп место"/>
                  <InputGroup v-model="additionalSpace"
                              class="sm mb-0 ml-2"
                              type="number"
                              :readonly="!additionalSpaceIncluded"
                              @change="saveAdditionalSpace"/>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-2">Месячный платеж: {{ formatNumber(amountPerMonth, true) }} {{ currency }}</div>
        </div>
        <div class="col-md-6">
          <div>Оплачено до: {{ clinicFull.tariffInfo ? formatDate(clinicFull.tariffInfo.paid_till) : '-' }}</div>
          <div>Статус аккаунта: <span v-if="clinicFull.blocked" class="text-danger">Заблокировано</span><span v-else class="text-primary">Активно</span></div>
          <div>Статус клиента: {{ clinicFull.clientStatus }}</div>
        </div>
      </div>

      <div v-if="clinicTariff" class="mt-3">
        <div>Комментарий: {{ clinicTariff.comment ? clinicTariff.comment : '-' }}
          <a @click="editComment"
             class="pointer ml-2">
            <EditSvg/>
          </a>
          <div v-if="editingComment" class="text-right">
            <AutosizeTextArea v-model="newComment"/>
            <button @click="saveComment"
                    class="btn-themed btn-themed-squared">
              {{ $t('save') }}
            </button>
          </div>
        </div>

      </div>
    </div>

    <div class="row">
      <div class="col-md-3">
        <SelectGroup :options="months"
                     :showlabel="true"
                     v-model="object.months"
                     variant="white"
                     :translate="false"
                     :label="$t('months')"
                     :required="true"/>
      </div>
      <div class="col-md-3">
        <InputGroup :value="object.sum"
                    :label="$t('price')"
                    :readonly="true"
                    :required="true"/>
      </div>
      <div class="col-md-3">
        <InputGroup v-model="object.discount_percent"
                    :label="$t('discount') + ' (%)'"
                    :key="discountCount"
                    :required="true"/>
      </div>
      <div class="col-md-3">
        <InputGroup v-model="manualDiscountedSum"
                    @change="manualDiscountedSumChanged"
                    :label="$t('sum')"
                    :required="true"/>
      </div>
    </div>
    <div class="btn-group w-100 mb-3">
      <a class="btn btn-month w-50"
         @click="object.months = 6">
        6 мес - 10%
      </a>
      <a class="btn btn-month w-50"
         @click="object.months = 12">
        12 мес - 20%
      </a>
    </div>
    <div>
      Итого основная сумма: <b>{{ formatNumber(discountedSum, true) }} {{ currency }}</b>
    </div>

    <div class="mt-3 card p-3 bg-gray border-0">
      <div class="font-weight-bold mb-2 text-muted">Доп услуги</div>
      <Autocomplete placeholder="Добавить дополнительную услугу"
                    model="billing_additional_service"
                    class="template-like"
                    @input="addAdditionalService"/>
      <div>
        <table class="table-blue bg-white services-table mt-3"
               v-if="object.additional_services.length">
          <thead>
          <tr>
            <td>Услуга</td>
            <td>Кол-во</td>
            <td>Цена ({{ currency }})</td>
            <td>Стоимость (kzt)</td>
            <td></td>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(serv, ind) in object.additional_services"
              :key="`s-${serv.id}`">
            <td>{{ serv.title }}</td>
            <td>
              <input type="number"
                     min="1"
                       v-model="serv.amount"
                       class="form-control sm"/>
            </td>
            <td>
              <input type="number"
                     min="0"
                     v-model="serv['price_'+currency]"
                     @keyup="serv['price'] = serv['price_'+currency] * exchangeRateToKzt"
                     class="form-control sm"/>
            </td>
            <td>{{ formatNumber(serv['price_'+currency] * serv.amount * exchangeRateToKzt) }}</td>
            <td>
              <a class="pointer"
                 @click="removeAdditionalService(ind)"><TrashSvg/>
              </a>
            </td>
          </tr>
          </tbody>
          <tfoot class="font-weight-bolder">
          <tr class="border-top">
            <td>Итого</td>
            <td></td>
            <td>{{ formatNumber(additionalServicesSum) }}</td>
            <td>{{ formatNumber(additionalServicesSumKzt) }}</td>
            <td></td>
          </tr>
          </tfoot>
        </table>
      </div>
    </div>

    <div class="mt-3">
      Итого сумма в KZT (для расчета бонусов): <b>{{ formatNumber(discountedFinalSum) }}</b>
    </div>

  </div>
</template>

<script>
import { EloquentService, SuperadminService } from "@/services/api.service"
import SelectGroup from "@/components/form/SelectGroup.vue"
import { formatDate, formatNumber } from "@/extensions/filters/filters"
import EditSvg from '@/assets/svg-vue/general/edit.svg'
import AutosizeTextArea from "@/components/form/AutosizeTextArea.vue"
import InputGroup from "@/components/form/InputGroup.vue"
import CheckboxGroup from "@/components/form/CheckboxGroup.vue"
import TrashSvg from "@/assets/svg-vue/general/delete.svg"
import { appendFormdata } from "@/extensions/prototypes/prototypes"
import Autocomplete from "@/components/parts/general/Autocomplete.vue"
// import EyeSvg from "@/assets/svg-vue/form/eye.svg.vue"

export default {
  name: "BillingPaymentFormCard",
  components: {
    Autocomplete,
    TrashSvg,
    CheckboxGroup,
    InputGroup,
    AutosizeTextArea,
    SelectGroup,
    EditSvg
  },
  props: {
    object: {},
    selectModels: {
      type: Object
    },
    options: {}
  },
  data() {
    return {
      clinicFull: null,
      currencyMapping: {
        1: 'uah',
        2: 'rub',
        3: 'usd',
        5: 'kzt'
      },
      additionalDoctors: 0,
      additionalDoctorsIncluded: true,
      additionalSpace: 0,
      additionalSpaceIncluded: true,
      rates: [],
      discountCount: 1,
      months: [ ...Array(12).keys() ].map( i => ({ id: (i+1), title: (i+1)})),
      editingComment: false,
      newComment: '',
      manualDiscountedSum: 0,
      allTariffPaidFunctionality: []
    }

  },
  computed: {
    clinicId() {
      return this.object.clinic_id || null
    },
    amountPerMonth() {
      if((this.clinicFull?.tariffPrices || 0) === 0) return 0
      let sum = 0
      const currency = this.object.currency
      if(this.object.include_payment_for_core_tariff) {
        sum += Number(this.clinicFull?.tariffPrices?.tariff?.[currency] || 0)
        // sum += Number(this.clinicFull?.tariffPrices?.additional_space?.[currency] || 0)
      }
      sum += this.additionalDoctorsIncluded ? Number(this.clinicFull?.tariffPrices?.additional_doctors?.[currency] || 0) : 0
      sum += this.additionalSpaceIncluded ? Number(this.clinicFull?.tariffPrices?.additional_space?.[currency] || 0) : 0
      this.clinicTariff.paid_functionality.forEach(x => {
        if(this.object.paid_functionality.includes(x.id) && x.pivot?.additional_payment) {
          sum += Number(JSON.parse(x.pivot.additional_payment)[currency])
        }
      })
      console.log(sum)
      return sum * (1 - this.inherentDiscount)
    },
    clinicTariff() {
      return this.clinicFull?.clinic_tariff || null
    },
    paidFunctionalityString() {
      if(!this.clinicTariff?.paid_functionality) return '-'
      return this.clinicTariff?.paid_functionality?.map(x => `[${x.id}] ${x.title}`).join(', ')
    },
    inherentDiscount() {
      const total = Number(this.clinicFull?.tariffPrices?.total?.[this.currency] || 0)
      if(total <= 0 || this.tariffFullStandardSum <= 0 || total === this.tariffFullStandardSum) return 0
      // return Math.round(((this.tariffFullStandardSum - total) / this.tariffFullStandardSum) * 100) / 100
      return ((this.tariffFullStandardSum - total) / this.tariffFullStandardSum)
    },
    discountedSum() {
      return Math.round((this.object.sum - (this.object.sum * (this.object.discount_percent || 0) / 100)) * 100) / 100
    },
    additionalServicesSum() {
      return this.object.additional_services.reduce((acc, val) => acc + val['price_'+this.currency] * val.amount, 0)
    },
    additionalServicesSumKzt() {
      return this.additionalServicesSum * this.exchangeRateToKzt
    },
    tariffFullStandardSum() {
      const items = ['tariff', 'additional_space', 'additional_doctors', 'paid_functionality']
      const nums = items.map(item => Number(this.clinicFull?.tariffPrices?.[item]?.[this.currency] || 0))
      return nums.reduce((partialSum, a) => partialSum + a, 0)
    },
    discountedFinalSum() {
      return Math.round(this.discountedSum * this.exchangeRateToKzt + this.additionalServicesSumKzt)
    },
    currency() {
      return this.clinicFull?.billingCurrency || 'kzt'
    },
    exchangeRateToKzt() {
      return this.rates.find(rate => rate.currency_to === 'KZT' && rate.currency_from === this.currency.toUpperCase())?.rate || 1
    },
    availableFunctionality() {
      const ids = this.clinicTariff?.paid_functionality?.map(x => x.id)
      return this.allTariffPaidFunctionality.filter(func => !ids.includes(func.id))
    }
  },
  watch: {
    async clinicId(val) {
      if(!val) return
      this.allTariffPaidFunctionality = []
      await this.reload()
      // this.object.currency = this.currencyMapping[this.clinicFull.currency.id] || 'usd'
      this.object.currency = this.currency
      this.recountSum()
    },
    'object.months'(val) {
      if(val == 6) {
        this.object.discount_percent = 10
      }
      if(val == 12) {
        this.object.discount_percent = 20
      }
      this.recountSum()
    },
    amountPerMonth() {
      this.recountSum()
    },
    'object.discount_percent'(val) {
      if(Number(val) > 100) {
        this.object.discount_percent = 99
        this.discountCount++
      }
      this.recountSum()
    },
    additionalDoctorsIncluded(val) {
      this.object.additional_doctors = val ? this.additionalDoctors : 0
    },
    additionalSpaceIncluded(val) {
      this.object.additional_space = val ? this.additionalSpace : 0
    }
  },
  methods: {
    formatDate,
    formatNumber,
    recountSum() {
      this.object.sum = Math.round(this.object.months * this.amountPerMonth * 100) / 100
      this.manualDiscountedSum = this.discountedSum
    },
    async reload() {
      this.clinicFull = (await EloquentService.show('clinic', this.clinicId.code, true)).data
      this.object.manager_id = this.clinicFull.manager_id
      this.object.partner_id = this.clinicFull.partner_id
      this.object.paid_functionality = this.clinicTariff?.paid_functionality?.map(x => x.id) || []
      this.allTariffPaidFunctionality = this.clinicFull.allTariffPaidFunctionality || []
      this.additionalDoctors = this.clinicTariff?.additional_doctors || 0
      this.additionalSpace = this.clinicTariff?.additional_space || 0
      this.object.additional_doctors = this.additionalDoctorsIncluded ? this.additionalDoctors : 0
      this.object.additional_space = this.additionalSpaceIncluded ? this.additionalSpace : 0
      this.object.additional_services = []
    },
    editComment() {
      if(this.editingComment) {
        this.editingComment = false
        this.newComment = ''
        return
      }
      this.editingComment = true
      this.newComment = this.clinicTariff.comment
    },
    async saveComment() {
      if(!this.clinicId || !this.clinicId.code) return
      await SuperadminService.saveTariffsForClinic(this.clinicId.code, { comment: this.newComment })
      await this.reload()
      this.editingComment = false
    },
    async addFunctionality(id) {
      if(this.object.paid_functionality.includes(id)) return
      this.object.paid_functionality.push(id)
      await this.changeFunctionality()
    },
    async removeFunctionality(id) {
      if(!this.clinicFull) return
      this.object.paid_functionality = this.object.paid_functionality.filter(x => x !== id)
      await this.changeFunctionality()
    },
    addAdditionalService(service) {
      const existingService = this.object.additional_services.find(obj => obj.id === service.id);
      if (existingService) {
        existingService.amount += 1
      } else {
        this.object.additional_services.push({
          ...service, amount: 1,
          price: service['price_'+this.currency] * this.exchangeRateToKzt
        })
      }
    },
    removeAdditionalService(index) {
      this.object.additional_services.splice(index, 1)
    },
    async changeFunctionality() {
      let formData = new FormData()
      formData = appendFormdata(formData, {
        paid_functionality: this.object.paid_functionality.length ? this.object.paid_functionality : ''
      })
      await EloquentService.update('clinic_tariff', this.clinicFull.id, formData, true)
      await this.reload()
    },
    async manualDiscountedSumChanged() {
      await this.$nextTick()
      this.object.discount_percent = Math.round(((this.object.sum - this.manualDiscountedSum) / this.object.sum) * 1000) / 10
    },
    async saveAdditionalDoctors() {
      let formData = new FormData()
      formData = appendFormdata(formData, {
        additional_doctors: this.additionalDoctors
      })
      await EloquentService.update('clinic_tariff', this.clinicFull.id, formData, true)
      await this.reload()
    },
    async saveAdditionalSpace() {
      let formData = new FormData()
      formData = appendFormdata(formData, {
        additional_space: this.additionalSpace
      })
      await EloquentService.update('clinic_tariff', this.clinicFull.id, formData, true)
      await this.reload()
    }
  },
  async mounted() {
    this.object.include_payment_for_core_tariff = true
    this.object.additional_services = []
    const data = await EloquentService.dataTable('billing_currency_rate', 1, {
      pagination: 1000,
    }, true, true)
    this.rates = data.data.result.data
  }
}
</script>

<style scoped lang="scss">
.btn-month {
  cursor: pointer;
  border: 1px solid gray;
  border-radius: 4px;
  display: block;
  padding: 4px 0;
  font-size: 80%;
  &:hover {
    background: $light-back;
  }
}
</style>
