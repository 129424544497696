<template>
  <div class="dc-menu"
       :style="{
          'left': cx + 'px',
          'top': cy + 'px',
      }">
    <template v-if="!isOnlineBooking">
      <DMenuItem :type="'PAY'"
                 v-if="isTreatment && record.is_paid != '1' && rights.includes('accept_payments')"
                 @click.native="acceptPayment"
                 :title="$t('accept_payment')" />
      <DMenuItem :type="'CONFIRM'"
                 v-if="isTreatment && record && record.readableStatus === 'not_confirmed' && canBeEdited && rights.includes('recording_status')"
                 @click.native="confirmVisit"
                 :title="$t('confirm_visit')" />
      <DMenuItem :type="'CAME'"
                 v-if="isTreatment && record && ['confirmed'].includes(record.readableStatus) && canBeEdited && rights.includes('recording_status')"
                 @click.native="patientCame"
                 :title="$t('patient_came')" />
      <DMenuItem :type="'VIEW'"
                 v-if="canEditRecords"
                 :title="$t('edit_visit')"
                 @click.native="quickView" />
      <DMenuItem :type="'EDIT'"
                 v-if="isTreatment && canBeEdited && canEditRecords"
                 :title="$t('fill_treatment')"
                 @click.native="navigateToVisit" />
      <DMenuItem :type="'PATIENT'"
                 v-if="patient && rights.includes('patient_card')"
                 @click.native="navigateToPatientCard"
                 :title="$t('patient_card')" />
      <DMenuItem :type="'PHONE'"
                 v-if="patient && patient.phone && !rights.includes('hide_patient_phone')"
                 @click.native="callPhone"
                 :title="patient.phone" />
      <DMenuItem :type="'SMS'"
                 v-if="patient && patient.phone && rights.includes('sms_send') && !rights.includes('hide_patient_phone')"
                 @click.native="sendSms"
                 :title="$t('send_sms')" />
      <DMenuItem :type="'EMAIL'"
                 v-if="patient && patient.email && rights.includes('emailing')"
                 @click.native="sendMail"
                 :title="$t('send_mail')" />
      <DMenuItem :type="'MOVE'"
                 v-if="canEditRecords"
                 @click.native="moveRecord"
                 :title="$t('move_record')" />
      <DMenuItem :type="'COPY'"
                 v-if="rights.includes('add_records')"
                 @click.native="copyRecord"
                 :title="$t('record_again')" />
      <div class="line" v-if="isTreatment && canBeEdited && record.is_paid != '1' && rights.includes('add_records') && rights.includes('recording_status')"></div>
      <DMenuItem :type="'DID_NOT_COME'"
                 v-if="isTreatment && record && ['confirmed','not_confirmed'].includes(record.readableStatus) && canBeEdited && rights.includes('recording_status')"
                 @click.native="patientDidNotCome"
                 :title="$t('did_not_come')" />
      <DMenuItem :type="'CANCEL'"
                 v-if="isTreatment && canBeEdited && record.is_paid != '1' && rights.includes('add_records') && rights.includes('recording_status')"
                 :title="$t('cancel_record')"
                 @click.native="cancelRecord" />
      <DMenuItem :type="'DELETE'"
                 v-if="!isTreatment && canBeEdited && record.is_paid != '1' && rights.includes('add_records')"
                 :title="isTreatment ? $t('delete_record') : $t('delete')"
                 @click.native="deleteRecord" />
    </template>
    <template v-if="isOnlineBooking">
      <DMenuItem :type="'CAME'"
                 v-if="rights.includes('add_records')"
                 :title="$t('confirm_visit')"
                 @click.native="confirmOnline" />
      <DMenuItem :type="'CANCEL'"
                 v-if="canBeEdited && record.is_paid != '1' && rights.includes('add_records') && rights.includes('recording_status')"
                 :title="$t('cancel_record')"
                 @click.native="cancelRecord" />
    </template>
  </div>
</template>

<script>

import DMenuItem from "@/components/pages/calendar/calendar/DMenuItem";
import { CalendarService, EloquentService, PatientService } from "@/services/api.service"
import { mapState } from "vuex"
import moment from "moment"
import { makeCall } from "@/services/call.service"

export default {
  name: "DMenu",
  props: {
    record: Object,
    cordX: Number,
    cordY: Number,
    contextMenuHeight: Number,
  },
  components: {
    DMenuItem
  },
  data() {
    return {
      offset: 40,
      cx: this.cordX,
      cy: this.cordY + this.offset,
      initialY: document.documentElement.scrollTop || document.body.scrollTop,
      clicksEnabled: false
    }
  },
  computed: {
    ...mapState({
      rights: state => state.auth.rights,
      doctorSchedules: state => state.calendar.doctorSchedules,
      chairs: state => state.auth.branchChairs,
      clinic: state => state.auth.clinic,
      user: state => state.auth.user,
    }),
    patient() {
      return this.record ? this.record.patient : {}
    },
    isTreatment() {
      return this.record && this.record.record_type !== 'block'
    },
    isOnlineBooking() {
      return this.record?.record_type === 'online'
    },
    canBeEdited() {
      if(!this.record) return false
      if(this.record.startMoment.format('YYYY-MM-DD') === moment().format('YYYY-MM-DD') && this.rights.includes('records_during_day')) {
        return true
      }
      return !(this.record.hasPassed && !this.rights.includes('edit_history'))
    },
    additional() {
      return this.clinic.clinic_additional
    },
    canEditRecords() {
      if(this.rights.includes('add_records')) return true
      if(this.record?.doctor_id === this.user.id && this.rights.includes('edit_own_records')) {
        return true
      }
      return false
    }
  },
  mounted() {
    if((window.innerHeight - this.contextMenuHeight) >= this.cordY) {
      this.offset = 0
    }
    window.addEventListener('scroll', this.handleScroll)
    this.handleScroll()
    setTimeout(() => this.clicksEnabled = true, 250)
  },
  unmounted() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll() {
      let top = document.documentElement.scrollTop || document.body.scrollTop
      this.cy = this.cordY - top + this.initialY + this.offset
    },
    navigateToVisit() {
      if(!this.clicksEnabled) return
      this.$router.push({
        name: 'PatientVisitPage',
        params: {
          id: this.record.client_id,
          visitid: this.record.id,
          page: 'description'
        }
      }).catch(()=>{})
    },
    quickView() {
      if(!this.clicksEnabled) return
      this.$store.commit('setNewVisit', {
        id: this.record.id,
        client_id: this.record.client_id
      })
      this.$bvModal.show('add-new-visit-modal')
    },
    confirmOnline() {
      if(!this.clicksEnabled) return
      let chair = null
      if(!this.record.chair && this.record.doctor_id) { // trying to get chair from schedule
        chair = this.$store.getters.getChairForRecord(this.record)
      }
      this.$store.commit('setNewVisit', {
        id: this.record.id,
        client_id: this.record.patient,
        chair_id: chair?.id || null,
        chair
      })
      this.$bvModal.show('add-new-visit-modal')
    },
    navigateToPatientCard() {
      if(!this.clicksEnabled) return
      this.$router.push({
        name: 'PatientVisits',
        params: {
          id: this.record.client_id,
        }
      }).catch(()=>{})
    },
    sendMail() {
      if(!this.clicksEnabled) return
      this.$store.commit('setPatient', this.copyObject(this.patient))
      this.$bvModal.show('send-mail-modal')
    },
    sendSms() {
      if(!this.clicksEnabled) return
      this.$store.commit('setPatient', this.copyObject(this.patient))
      this.$bvModal.show('patient-sms-modal')
    },
    callPhone() {
      if(!this.clicksEnabled) return
      makeCall(this.clinic, this.patient.phone)
    },
    moveRecord() {
      if(!this.clicksEnabled) return
      this.$store.commit('setRecordToMove', this.record)
      this.$store.commit('setContextMenuVisible', false)
    },
    copyRecord() {
      if(!this.clicksEnabled) return
      this.$store.commit('setRecordToCopy', this.record)
      this.$store.commit('setContextMenuVisible', false)
    },
    confirmVisit() {
      if(!this.clicksEnabled) return
      this.changeStatus(2)
    },
    patientCame() {
      if(!this.clicksEnabled) return
      this.changeStatus(3)
    },
    async patientDidNotCome() {
      if(!this.clicksEnabled) return
      let reason = ''
      if(this.additional.ask_reason_not_come) {
        reason = await this.$parent?.$parent?.$refs.reasonPrompt.showPrompt()
        if(!reason) return
      }
      this.changeStatus(5, reason)
    },
    changeStatus(status, reason = null) {
      if(!reason) reason = 'hello'
      let formData = new FormData()
      formData = this.appendFormdata(formData, { records_statuses_id: status, reason })
      EloquentService.update('record', this.record.id, formData).then(() => {
        this.$noty.info(this.$t('success_message'))
        this.$store.commit('incrementDataTableCounter')
      }).catch((e) => {
        console.log(e)
      })
    },
    async acceptPayment() {
      if(!this.clicksEnabled) return
      let patientData = this.copyObject(this.patient)
      const patientLatest = (await PatientService.getPatient(this.patient.id)).data
      patientData.parent_family_accounts = patientLatest.parent_family_accounts
      patientData.discount = patientLatest.discount
      patientData.deposit_by_accounts = patientLatest.deposit_by_accounts
      patientData.insurance_polises = patientLatest.insurance_polises
      patientData.representative_fio = patientLatest.representative_fio
      patientData.representative_id = patientLatest.representative_id
      this.$store.commit('setVisit', this.copyObject(this.record))
      this.$store.commit('setPatient', patientData)
      await this.$nextTick()
      this.$bvModal.show('visit-pay-modal')
    },
    deleteRecord() {
      if(!this.clicksEnabled) return
      if(confirm(this.$t('are_you_sure_you_want_to_delete_record'))) {
        EloquentService.delete('record', this.record.id).then(() => {
          this.$noty.info(this.$t('success_message'))
          this.$store.commit('incrementDataTableCounter')
        })
      }
    },
    async cancelRecord() {
      if(!this.clicksEnabled) return
      let reason = ''
      if(this.additional.ask_reason_cancel) {
        reason = await this.$parent?.$parent?.$refs.reasonPrompt.showPrompt()
        if(!reason) return
      }
      CalendarService.cancelRecord(this.record.id, reason).then(() => {
        this.$noty.info(this.$t('success_message'))
        this.$store.commit('incrementDataTableCounter')
      }).catch((e) => {
        console.log(e)
      })
    }
  }
}
</script>

<style scoped>
.dc-menu {
  padding: 10px 0;
  position: fixed;
  background: white;
  border-radius: 15px;
  overflow: hidden;
  z-index: 3;
  font-size: 13px;

  -webkit-box-shadow: 0px 1px 12px 1px rgba(34, 60, 80, 0.2);
  -moz-box-shadow: 0px 1px 12px 1px rgba(34, 60, 80, 0.2);
  box-shadow: 0px 1px 12px 1px rgba(34, 60, 80, 0.2);
}
.dc-menu svg {
  margin-top: -4px;
}
.line {
  border-top: 1px solid #e2e2e2;
  margin: 5px 0;
}
</style>
