export default {
    additional: "Əlavə",
    alternative: "Alternativ",
    amount_completed: "Tamamlanmış məbləğ",
    apply_plan_treatment: "Müalicə planını tətbiq edin",
    are_you_sure_you_want_to_cancel_plan: "Müalicə planını ləğv etmək istədiyinizə əminsiniz?",
    calls_planning: "Zənglərin planlaşdırılması",
    choose_criterias_for_fill: "Doldurmaq üçün lazım olan meyarları seçin",
    comments: "Şərhlər",
    completed_sum: "Tamamlanmış məbləğ",
    document_header: "Müalicə planında şapka",
    grouped: "Qruplaşdırılmış",
    grouped_by_teeth: "Dişlərə görə qruplaşdırılmış",
    grouped_by_services: "Xidmətlərə görə qruplaşdırılmış",
    last_update: "Son dəyişiklik",
    level_description: "Mərhələ təsviri",
    main_complaints: "Əsas şikayətlər",
    not_signed: "İmzalanmamış",
    notification_date: "Bildiriş tarixi",
    plan: "Plan",
    plan_treatment_status: "Müalicə planının statusu",
    plan_type: "Plan növü",
    planned_sum: "Planlaşdırılmış məbləğ",
    recommended: "Tövsiyə edilən",
    reset_all: "Hamısını sıfırla",
    route_list: "Marşrut vərəqi",
    select_all: "Hamısını seçin",
    sign_without_upload: "Yükləmədən imzala",
    signed_at: "İmzalanıb",
    total_sum: "Ümumi məbləğ",
    treatment_plan_amount: "Müalicə planının məbləği",
}
