import { doctorAdapter } from "@/adapters/staff/doctor"
import options from "@/data/staff/doctor"
import { copyObject } from "@/extensions/prototypes/prototypes"
import administrator_options from "@/data/staff/administrator"

let assistant_options = copyObject(options)
assistant_options.model = 'assistant'
administrator_options.order = 'lname'
assistant_options.fields.forEach(item => {
    if(['sort_order'].includes(item.id)) { item.table = false }
    if(['warehouse_id', 'doctors_profession_id', 'sort_order'].includes(item.id)) { delete item.form }
    if(['full_name'].includes(item.id)) { item.tdcomponent = 'StaffDatatableTDStaffName' }
    if(item.form && item.form.type === 'separator') { delete item.form }
})
assistant_options.fields.splice(12, 0 , {
    id: 'profession_string',
    title: 'profession',
    table: false,
    form: {
        classes: 'col-md-4',
        readonly: true,
        type: "text"
    },
})
assistant_options.fields.push({
    id: 'rate_per_day',
    title: 'rate_per_day',
    table: false,
    form: {
        type: "text",
        classes: 'col-md-4',
    },
})
assistant_options.fields.push({
    id: 'rate_per_hour',
    title: 'rate_per_hour',
    table: false,
    form: {
        type: "text",
        disabledForSelf: true,
        classes: 'col-md-4',
    },
})
assistant_options.fields.push({
    id: 'branches',
    title: 'branches',
    table: false,
    form: {
        model: 'field',
        type: "select",
        select_type: "model",
        multiple: true,
        select_data: {
            "title": "title"
        },
    },
})
// assistant_options.fields.push({
//     id: 'blocked',
//     title: 'is_deleted',
//     filter: true,
//     filter_boolean: true
// })
// delete assistant_options.actionscomponent
assistant_options.actionscomponent = 'StaffDatatableAssistantActions'
assistant_options.adapter = doctorAdapter
export default assistant_options
