import { doctorAdapter } from "@/adapters/staff/doctor"
import { countries } from "@/dictionaries/dictionary"

export default {
    primaryKey: "id",
    model: 'doctor',
    order: 'sort_order',
    modalsize: 'xl',
    sort: 'asc',
    adapter: doctorAdapter,
    deletemessage: 'are_you_sure_you_want_to_delete_user',
    trclass: [{ class: 'opacity-50', condition: 'blockedBool' }],
    showTotalRecords: true,
    actions: [
        "add",
        "excel",
        "edit",
        "delete"
    ],
    rights: {
        add: "create_docs",
        edit: "create_docs",
        delete: "create_docs",
    },
    actionscomponent: 'StaffDatatableDoctorActions',
    formComponent: 'StaffDatatableFormDoctorForm',
    prepareParams: ['id'],
    fields: [
        {
            id: 'id',
            title: 'id',
            table: true,
            sortable: true
        },
        {
            id: 'full_name',
            title: 'fio',
            table: true,
            sortable: 'lname',
            tabletdclass: "order-mob-0",
            tdcomponent: 'StaffDatatableTDDoctorName',
        },
        {
            id: 'fname',
            title: 'name',
            table: false,
            filter: true,
            form: {
                classes: 'col-md-4',
                type: "text",
                required: true
            }
        },
        {
            id: 'lname',
            title: 'surname',
            table: false,
            filter: true,
            form: {
                classes: 'col-md-4',
                type: "text",
                required: true
            }
        },
        {
            id: 'mname',
            title: 'middle_name',
            table: false,
            filter: true,
            form: {
                classes: 'col-md-4',
                type: "text"
            }
        },
        {
            id: 'email',
            title: 'email',
            table: true,
            sortable: true,
            filter: true
        },
        {
            id: 'gender',
            title: 'gender',
            table: false,
            form: {
                classes: 'col-md-4',
                type: "select",
                select_type: "list",
                options: {
                    "0": { id: "0", title: "male" },
                    "1": { id: "1", title: "female" },
                }
            }
        },
        {
            id: 'date_of_birth',
            title: 'date_of_birth',
            table: false,
            form: {
                classes: 'col-md-4',
                type: "date"
            }
        },
        {
            id: 'color',
            title: 'color',
            form: {
                classes: 'col-md-4',
                type: "color"
            }
        },
        {
            id: 'email',
            title: 'email',
            table: false,
            form: {
                classes: 'col-md-4',
                type: "text",
                required: true
            }
        },
        {
            id: 'mphone',
            title: 'phone',
            table: true,
            sortable: true,
            filter: true,
            form: {
                type: "text",
                classes: 'col-md-4',
                required: true
            },
            format_phone: true,
        },
        {
            id: 'mphone2',
            title: 'phone2',
            table: false,
            form: {
                type: "text",
                classes: 'col-md-4',
            },
            format_phone: true,
        },
        {
            id: 'percent',
            title: 'percent',
            table: false,
            form: {
                type: "text",
                disabledForSelf: true,
                classes: 'col-md-4',
            },
        },
        {
            id: 'rate',
            title: 'rate_per_month',
            table: false,
            form: {
                type: "text",
                disabledForSelf: true,
                classes: 'col-md-4',
            },
        },
        {
            id: 'snils',
            title: 'snils',
            table: false,
            filter: true,
            form: {
                classes: 'col-md-4',
                type: "text",
                tab: 'passport_data',
                conditionFunc(store) {
                    return store.state.auth.clinic.country_id == countries.RUSSIA
                }
            }
        },
        {
            id: 'taxcode',
            title: 'inn',
            table: false,
            filter: true,
            form: {
                classes: 'col-md-4',
                type: "text",
                tab: 'passport_data',
                conditionFunc(store) {
                    return store.state.auth.clinic.country_id == countries.RUSSIA
                }
            }
        },
        {
            id: 'profession',
            title: 'profession',
            table: true,
            sortable: false,
            tdcomponent: 'StaffDatatableTDProfession',
        },
        {
            id: 'last_login',
            title: 'last_login',
            table: true,
            sortable: false,
            tdcomponent: 'StaffDatatableTDLastLogin',
        },
        {
            id: 'sort_order',
            title: 'sorting',
            table: true,
            sortable: true,
        },
        {
            id: 'doctors_profession_id',
            title: 'profession',
            table: false,
            form: {
                type: "select",
                select_type: "model",
                select_data: {
                    "title": "title"
                },
                component: 'StaffDatatableFormProfession',
                // classes: 'col-md-4',
            },
        },
        {
            id: 'fields',
            title: 'fields',
            table: false,
            form: {
                model: 'field',
                type: "select",
                select_type: "model",
                multiple: true,
                select_data: {
                    "title": "translatedTitle"
                },
            },
        },
        {
            form: {
                classes: 'col-md-12',
                type: 'separator'
            }
        },
        {
            id: 'warehouse_id',
            title: 'warehouse',
            table: false,
            form: {
                type: "select",
                select_type: "model",
                select_data: {
                    "title": "title"
                },
                classes: 'col-md-12',
                note: 'without_warehouse_form_note'
            }
        },
        {
            id: 'prices',
            table: false,
            form: {
                component: "StaffDatatableFormPrices",
                classes: 'col-md-8',
            }
        },
        {
            id: 'report_days_limit',
            title: 'report_days_limit',
            table: false,
            form: {
                type: "number",
                disabledForSelf: true,
                classes: 'col-md-4',
            }
        },
        // {
        //     id: 'branch_id',
        //     title: 'branch',
        //     table: false,
        //     form: {
        //         type: "select",
        //         select_type: "model",
        //         select_data: {
        //             "title": "title"
        //         },
        //         classes: 'col-md-6',
        //     }
        // },
        {
            form: {
                classes: 'col-md-12',
                type: 'separator'
            }
        },
    ]
}
