export default {
    additional: "Дополнительный",
    alternative: "Альтернативный",
    amount_completed: "Сумма выполненная",
    apply_plan_treatment: "Применить план лечения",
    are_you_sure_you_want_to_cancel_plan: "Вы уверены, что хотите отменить план лечения?",
    calls_planning: "Планирование звонков",
    choose_criterias_for_fill: "Выберите необходимые критерии для заполнения",
    comments: "Комментарии",
    completed_sum: "Выполненная сумма",
    grouped: "Сгруппированный",
    grouped_by_teeth: "Сгруппированный по зубам",
    grouped_by_services: "Сгруппированный по услугам",
    last_update: "Последнее изменение",
    level_description: "Описание этапа",
    main_complaints: "Основные жалобы",
    not_signed: "Не подписан",
    notification_date: "Дата уведомления",
    plan: "План",
    plan_treatment_status: "Статус плана лечения",
    plan_type: "Тип плана",
    planned_sum: "Запланированная сумма",
    recommended: "Рекомендованный",
    reset_all: "Сбросить все",
    route_list: "Маршрутный лист",
    select_all: "Отметить все",
    sign_without_upload: "Подписать без загрузки",
    signed_at: "Подписан",
    total_sum: "Итого сумма",
    treatment_plan_amount: "Сумма плана лечения",
}
