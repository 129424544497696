<template>
  <b-modal id="approve-tech-support-login-modal"
           v-if="clinicId === 1843"
           :title="$t('clinic_access_request')"
           size="md"
           centered>
    {{ $t('dentist_plus_tech_support_requests_access_to_your_clinic') }}
    <template #modal-footer>
      <div class="modal-footer-buttons">
        <b-button class="cancel d-inline-block align-middle"
                  variant="themed"
                  @click="rejectAccess">
          {{ $t('reject') }}
        </b-button>
        <button :disabled="loading"
                @click="approveAccess"
                class="ml-3 btn-themed align-middle d-inline-block d-sm-inline-block btn-themed-squared">
          {{ $t('allow') }}
        </button>
      </div>
    </template>
  </b-modal>
</template>

<script setup>
import { ClinicService } from "@/services/api.service"
import { computed } from "vue"
// import { computed, getCurrentInstance, onBeforeMount } from "vue"
import { useStore } from "vuex"

const props = defineProps({
  superUserRequestingAccessId: Number,
  userIdToImitateLogin: {
    type: Number,
  }
})

const store = useStore()

const clinicId = computed(() => store.state.auth.clinic.id)
const loading = computed(() => store.state.dom.loading)

// let bvModal = null

// onBeforeMount(() => {
//   const instance = (getCurrentInstance())
  // bvModal = instance?.ctx?._bv__modal
// })

const approveAccess = async () => {
  await sendResponse(true)
}

const rejectAccess = async () => {
  await sendResponse(false)
}

const sendResponse = async (approved) => {
  const res = await ClinicService.clinicAccessResponse({
    clinicId: clinicId.value,
    superUserId: props.superUserRequestingAccessId,
    userIdToImitateLogin: props.userIdToImitateLogin,
    approved
  })
  // bvModal.hide('approve-tech-support-login-modal')
  console.log(res)
}
</script>
