<template>
  <span>
    <a @click="requestAccessToUser"
       v-if="['superadmin', 'supermanager', 'tech_specialist'].includes(role)"
       title="Запросить доступ"
       v-b-tooltip.hover
       class="tdclickable data-table-action">
    <LoginSvg class="svg-light svg-stroke-light svg-size-lg"/><span class="action-button-mob-title">Запросить доступ</span>
  </a>
    <a @click="restoreUser"
       v-if="object.blocked == 2 && ['superadmin', 'tech_specialist'].includes(role)"
       :title="$t('restore')"
       v-b-tooltip.hover
       class="tdclickable data-table-action">
    <RefreshSvg class="svg-light"/><span class="action-button-mob-title">{{ $t('restore') }}</span>
  </a>
    <a @click="resetPassword"
       v-if="['superadmin', 'supermanager', 'tech_specialist'].includes(role)"
       class="tdclickable data-table-action"
       :title="$t('reset_password')"
       v-b-tooltip.hover>
      <MailSvg class="svg-light"/><span class="action-button-mob-title">{{ $t('reset_password') }}</span>
    </a>
    </span>
</template>

<script>
import MailSvg from '@/assets/svg-vue/marketing/mail_forward.svg'
import RefreshSvg from '@/assets/svg-vue/form/tick.svg'
import LoginSvg from '@/assets/svg-vue/staff/login.svg'
import { SuperadminService } from "@/services/api.service"
import { mapState } from "vuex"

export default {
  name: "SuperAdminUsersActions",
  components: {
    RefreshSvg,
    MailSvg,
    LoginSvg
  },
  props: {
    object: {
      type: Object
    }
  },
  computed: {
    ...mapState({
      clinic: state => state.auth.clinic
    }),
    role() {
      return this.$store.state.auth.user.role
    }
  },
  methods: {
    async restoreUser() {
      try {
        const res = await SuperadminService.restoreUser(this.object.id)
        if(res.data) this.$store.commit('incrementDataTableCounter')
      } catch (e) {
        console.log(e)
      }
    },
    async resetPassword() {
      try {
        const res = await SuperadminService.resetUserPassword(this.clinic.id, this.object.id)
        if(res.data) this.$noty.info(this.$t('success_message'))
      } catch (e) {
        console.log(e)
      }
    },
    requestAccessToUser() {
      this.$store.commit('setUserIdToImitateLogin', this.object.id)
      this.$bvModal.show('tech-support-log-modal')
    }
  }
}
</script>
